/** @format */

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {isNil} from 'lodash-es';
import {ModelMapper} from 'model-mapper';
import {Observable, lastValueFrom} from 'rxjs';
import {map} from 'rxjs/operators';
import {Sensor} from '../classes/sensor';
import {LibConfig, LibConfigService} from '../config.service';

export interface ISensorFilter {
  hypervisionEquipmentId?: string;
  organizationalUnitId?: string;
  realEstateStructureId?: string;
  kinds?: string[];
  primary?: boolean;
  supervized?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SensorService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public list(filter: ISensorFilter): Promise<Sensor[]> {
    return lastValueFrom(
      this.http
        .get<any[]>(`${this.config.environment.apiUrl}/sensors`, {params: this.buildFilter(filter)})
        .pipe(map(data => data.map(d => new ModelMapper(Sensor).map(d))))
    );
  }

  public get(id: string): Observable<Sensor> {
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/sensors/${id}`)
      .pipe(map(data => new ModelMapper(Sensor).map(data)));
  }

  public update(id: string, data: any): Observable<Sensor> {
    return this.http
      .patch<any>(
        id ? `${this.config.environment.apiUrl}/sensors/${id}` : `${this.config.environment.apiUrl}/sensors`,
        data
      )
      .pipe(map(data => new ModelMapper(Sensor).map(data)));
  }

  public updateMany(data: {sensors?: any[]}): Observable<Sensor[]> {
    return this.http
      .patch<any>(`${this.config.environment.apiUrl}/sensors`, data)
      .pipe(map(data => data.map(d => new ModelMapper(Sensor).map(d))));
  }

  protected buildFilter(filter?: ISensorFilter): any {
    const reqFilter: any = {};
    if (filter.hypervisionEquipmentId) reqFilter.hypervisionEquipmentId = filter.hypervisionEquipmentId;
    if (filter.organizationalUnitId) reqFilter.organizationalUnitId = filter.organizationalUnitId;
    if (filter.realEstateStructureId) reqFilter.realEstateStructureId = filter.realEstateStructureId;
    if (filter.kinds?.length) reqFilter.kinds = filter.kinds;
    if (!isNil(filter.primary)) reqFilter.primary = filter.primary;
    if (!isNil(filter.supervized)) reqFilter.supervized = filter.supervized;
    return reqFilter;
  }
}
