/** @format */

import {map, values} from 'lodash-es';
import {propertyMap} from 'model-mapper';

export enum UserAnomalyReportNatureCategory {
  ELEVATOR = 'elevator',
  PARKING_DOOR = 'parking-door',
  ENERGY_FLUID = 'energy-fluid',
  FIRE_SAFETY = 'fire-safety',
  OTHER = 'other',
}

export const UserAnomalyReportNatureCategoryName = {
  [UserAnomalyReportNatureCategory.ELEVATOR]: 'Ascenseur',
  [UserAnomalyReportNatureCategory.PARKING_DOOR]: 'Porte de parking',
  [UserAnomalyReportNatureCategory.ENERGY_FLUID]: 'Énergie & fluide',
  [UserAnomalyReportNatureCategory.FIRE_SAFETY]: 'Sécurité incendie',
  [UserAnomalyReportNatureCategory.OTHER]: 'Autre',
};

export const UserAnomalyReportNatureCategoryColor = {
  [UserAnomalyReportNatureCategory.ELEVATOR]: 'var(--ion-color-elevator)',
  [UserAnomalyReportNatureCategory.PARKING_DOOR]: 'var(--ion-color-parking-door)',
  [UserAnomalyReportNatureCategory.ENERGY_FLUID]: 'var(--ion-color-energy-fluid)',
  [UserAnomalyReportNatureCategory.FIRE_SAFETY]: 'var(--ion-color-fire-safety)',
  [UserAnomalyReportNatureCategory.OTHER]: 'var(--ion-color-other)',
};

export const UserAnomalyReportNatureCategoryOptions = map(values(UserAnomalyReportNatureCategory), value => ({
  value,
  name: UserAnomalyReportNatureCategoryName[value],
  color: UserAnomalyReportNatureCategoryColor[value],
}));

export class UserAnomalyReportNature {
  @propertyMap()
  name: string;

  @propertyMap()
  category: UserAnomalyReportNatureCategory;

  @propertyMap()
  kind: 'failure' | 'anomaly';
}
