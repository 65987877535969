/** @format */

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {cloneDeep, get, isEqual, map, merge, reduce, round} from 'lodash-es';
import moment, {Moment} from 'moment';
import {lastValueFrom} from 'rxjs';
import {
  AggregationValue,
  ChartComponent,
  EquipmentStatusHistoryField,
  Metric,
  SensorDataService,
  SignatureFlowState,
} from 'sesio-lib';
import {EquipmentStatusHistoryService} from '../../../_services/equipment-status-history.service';
import {UserService} from '../../../_services/user.service';

@Component({
  selector: 'app-equipment-traffic-chart',
  templateUrl: './equipment-traffic-chart.component.html',
  styleUrls: ['./equipment-traffic-chart.component.scss'],
})
export class EquipmentTrafficChartComponent implements OnInit {
  @Input()
  id: string;

  @Input('filter')
  public set setFilter(filter: any) {
    if (!isEqual(this.filter, filter)) {
      this.filter = cloneDeep(filter);
      if (this.chart) this.loadData();
    }
  }
  private filter: any;

  @Output('trafficData')
  public trafficDataEmitter = new EventEmitter<AggregationValue[]>();

  @Output('noflowData')
  public noflowDataEmitter = new EventEmitter<AggregationValue[]>();

  private chart: ChartComponent;

  private tooltipFormatter = () => {
    const self = this;
    return function () {
      return `
      <small>${moment(this.x).format('L LT')}</small><br/>
      ${reduce(
        this.points,
        (res, point) => `
        ${res}
        <span style="color:${point.color}">\u25CF</span>
        ${point.series.name}: <b>${round(point.y)}</b><br/>
      `,
        ''
      )}
    `;
    };
  };

  options = {
    chart: {zoomType: 'x'},
    credits: {enabled: false},
    exporting: {enabled: false},
    title: {text: ''},
    rangeSelector: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      split: false,
      useHTML: true,
      formatter: this.tooltipFormatter(),
    },
    legend: {
      enabled: true,
    },
    xAxis: {ordinal: false},
    yAxis: [
      {
        showLastLabel: true,
        opposite: false,
        lineColor: 'var(--ion-color-primary)',
        labels: {style: {color: 'var(--ion-color-primary)'}},
        lineWidth: 1,
        resize: {enabled: true},
        min: 0,
        tooltipValueFormat: '{value:.0f}',
      },
      {
        title: {text: 'Absence de flux', style: {color: '#9564e2'}},
        showLastLabel: true,
        opposite: true,
        lineColor: '#9564e2',
        labels: {style: {color: '#9564e2'}},
        lineWidth: 1,
        resize: {enabled: true},
        min: 0,
      },
      {
        title: {text: 'Absence de flux info', style: {color: '#999'}},
        showLastLabel: true,
        opposite: true,
        lineColor: '#999',
        labels: {style: {color: '#999'}},
        lineWidth: 1,
        resize: {enabled: true},
        min: 0,
      },
    ],
    series: [
      {
        name: 'Trafic',
        type: 'area',
        threshold: null,
        step: true,
        color: '#21698e',
      },
      {
        name: 'Absence de flux',
        type: 'line',
        step: true,
        yAxis: 1,
        color: '#9564e2',
      },
      ...(this.userService.$user.value?.administrator
        ? [
            {
              name: 'Ouverture',
              type: 'column',
              yAxis: 2,
            },
            {
              name: 'Fermeture',
              type: 'column',
              yAxis: 2,
            },
          ]
        : []),
    ],
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false,
        },
      },
    },
  };
  private extremes: {start?: Moment; end?: Moment};
  private loadDataEcho: number;

  constructor(
    private sensorDataService: SensorDataService,
    private equipmentStatusHistoryService: EquipmentStatusHistoryService,
    private userService: UserService
  ) {}

  ngOnInit() {}

  public setExtremes(start?: Moment, end?: Moment) {
    this.extremes = {start, end};
    if (this.chart) this.loadData();
  }

  async loadData(chartComponent?: ChartComponent): Promise<void> {
    if (!this.chart) this.chart = chartComponent;
    this.chart.setLoading(true);
    const search = merge({equipmentId: this.id, kinds: [Metric.MOTION_COUNT]}, this.filter, {
      start: this.extremes?.start,
      end: this.extremes?.end,
    });
    this.loadDataEcho = Date.now();
    const echo = this.loadDataEcho;
    await Promise.all([this.loadTrafficData(search, echo), this.loadNoflowData(search, echo)]);
    if (this.extremes) {
      this.chart.setExtremes(this.extremes.start, this.extremes.end);
    }
    this.chart.setLoading(false);
  }

  async loadTrafficData(search: any, echo: number) {
    const data = get(
      await lastValueFrom(
        this.sensorDataService.getChartRangeData(
          merge(
            {
              unitGrouping: this.getGrouping(),
            },
            search
          )
        )
      ),
      Metric.MOTION_COUNT
    );
    if (echo !== this.loadDataEcho) return;
    this.trafficDataEmitter.next(data);
    this.chart.setSerieData(map(data, d => [d.name, d.value]));
  }

  private noflowData: any[];
  async loadNoflowData(search: any, echo: number) {
    this.noflowData = get(
      await lastValueFrom(
        this.equipmentStatusHistoryService.getChartRangeData(
          merge(
            {
              field: EquipmentStatusHistoryField.SIGNATURE_FLOW_STATE,
              statuses: [SignatureFlowState.NOFLOW],
              unit: this.getGrouping(),
              binSize: 1,
            },
            search
          )
        )
      ),
      SignatureFlowState.NOFLOW
    );
    if (echo !== this.loadDataEcho) return;
    this.noflowDataEmitter.emit(this.noflowData);
    this.chart.setSerieData(
      map(this.noflowData, d => [d.name, d.value]),
      1
    );
    if (this.userService.$user.value?.administrator) {
      this.chart.setSerieData(
        map(this.noflowData, d => [d.name, d.opened]),
        2
      );
      this.chart.setSerieData(
        map(this.noflowData, d => [d.name, d.closed]),
        3
      );
    }
  }

  private getGrouping() {
    return this.extremes.end?.diff(this.extremes.start, 'months', true) >= 0.99 ? 'day' : 'hour';
  }
}
