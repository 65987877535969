/** @format */

export interface IColumn {
  data: string;
  name: string;
  searchable?: boolean;
  search?: {value: any; regex: boolean};
}

export interface ISearchResult {
  draw: string;
  recordsTotal: number;
  recordsFiltered: number;
  data: {_id: string; [property: string]: any}[];
}

export function addColumnSearch(columns: IColumn[], field: string, value: any, regex: boolean = true): void {
  let column = columns.find(c => c.name === field);
  if (!column) {
    columns.push((column = {data: field, name: field, searchable: true}));
  }
  column.search = {value, regex};
}
