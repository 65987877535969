/** @format */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IntentService as SesioIntentService } from 'sesio-lib';
import { environment } from '../../environments/environment';
import { IDatatableOptions, IDatatableRecords } from '../_components/datagrid/datatable.class';

@Injectable({
  providedIn: 'root',
})
export class IntentService extends SesioIntentService {
  public datatable(options: {
    equipmentReference: string;
    kind: string;
    query: IDatatableOptions;
    operationReference?: string;
  }): Observable<IDatatableRecords<any>> {
    return this.http.post<any>(`${environment.apiUrl}/intent/datatable`, options);
  }
}
