/** @format */

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {lastValueFrom, map} from 'rxjs';
import {ISearchResult} from '../classes/search-result';
import {UserAnomalyReportNature, UserAnomalyReportNatureCategory} from '../classes/user-anomaly-report-nature';
import {LibConfig, LibConfigService} from '../config.service';
import {ModelMapper} from 'model-mapper';

export interface IUserAnomalyReportNatureFilter {
  kind?: 'anomaly' | 'failure';
  category?: UserAnomalyReportNatureCategory;
}

@Injectable({
  providedIn: 'root',
})
export class UserAnomalyReportNatureService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public find(
    options: {fields: string; limit?: number; start?: number},
    filter?: IUserAnomalyReportNatureFilter
  ): Promise<ISearchResult> {
    const query: any = {draw: Date.now().toString(), start: 0, columns: [], order: []};
    options.fields.split(' ').forEach((f, i) => {
      if (f.startsWith('-')) {
        query.order.push({column: i, dir: 'desc'});
        f = f.slice(1);
      } else if (f.startsWith('+')) {
        query.order.push({column: i, dir: 'asc'});
        f = f.slice(1);
      }
      query.columns.push({data: f, name: f, searchable: true});
    });
    if (options.limit) query.length = options.limit;
    if (options.start) query.start = options.start;
    return lastValueFrom(
      this.http.post<any>(`${this.config.environment.apiUrl}/user-anomaly-report-natures/datatable`, {query, filter})
    );
  }

  public get(id: string): Promise<UserAnomalyReportNature> {
    return lastValueFrom(
      this.http
        .get<any>(`${this.config.environment.apiUrl}/user-anomaly-report-natures/${id}`)
        .pipe(map(data => new ModelMapper(UserAnomalyReportNature).map(data)))
    );
  }

  public delete(id: string): Promise<boolean> {
    return lastValueFrom(
      this.http.delete<boolean>(`${this.config.environment.apiUrl}/user-anomaly-report-natures/${id}`)
    );
  }

  public create(data: any): Promise<UserAnomalyReportNature> {
    return lastValueFrom(
      this.http
        .post<any>(`${this.config.environment.apiUrl}/user-anomaly-report-natures`, data)
        .pipe(map(data => new ModelMapper(UserAnomalyReportNature).map(data)))
    );
  }

  public update(id: string, data: any): Promise<boolean> {
    return lastValueFrom(
      this.http.patch<boolean>(`${this.config.environment.apiUrl}/user-anomaly-report-natures/${id}`, data)
    );
  }

  protected buildSearchFilter(search: IUserAnomalyReportNatureFilter): any {
    const filter: any = {};
    if (search?.kind) filter.kind = search.kind;
    if (search?.category) filter.category = search.category;
    return filter;
  }
}
