/** @format */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import moment, { Moment } from 'moment';
import hash from 'object-hash';
import { ConnectableObservable, Observable, lastValueFrom } from 'rxjs';
import { map, publish, share, shareReplay, tap } from 'rxjs/operators';
import {
  AggregationValue,
  Elevator,
  EmbededUser,
  Equipment,
  EquipmentControl,
  EquipmentKind,
  EquipmentKindCategory,
  Family,
  Inspection,
  ParkingDoor
} from 'sesio-lib';
import { environment } from '../../environments/environment';
import { IHeaderSearch, buildHeaderSearchFilter } from '../_classes/header-search';
import { Option } from '../_classes/option';
import { IDatatableOptions, IDatatableRecords } from '../_components/datagrid/datatable.class';

export interface IEquipmentFilter extends IHeaderSearch {
  statuses?: string[];
  families?: string[];
  categories?: string[];
  contractorIds?: string[];
  equipmentKindIds?: string[];
  supervision?: boolean;
  hypervision?: boolean;
  realEstateStructureId?: string;
  equipmentId?: string;
  equipmentKindId?: string;
  equipmentKind?: EquipmentKind;
  signatureSettingName?: string;
  isTertiary?: boolean;
  supervised?: boolean;
}

export interface IJanitorInfo {
  group: string;
  data: {
    name: string;
    family: string;
    count: number;
    contractors: string[];
  }[];
}

export interface IEquipmentInfoFilter extends IEquipmentFilter {
  fields: string[];
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  private counts = {};

  constructor(private http: HttpClient) {}

  public get<T extends Equipment>(id: string, useKind = true): Observable<T> {
    return this.http.get<any>(`${environment.apiUrl}/equipments/${id}`).pipe(
      map(data => {
        switch (useKind ? data?.kind?.family : null) {
          case Family.ELEVATOR:
            return new ModelMapper(Elevator).map(data) as any;
          case Family.PARKING_DOOR:
            return new ModelMapper(ParkingDoor).map(data) as any;
          default:
            return new ModelMapper(Equipment).map(data) as any;
        }
      })
    );
  }

  public search(filter: {
    search?: string;
    category?: EquipmentKindCategory;
    family?: Family;
    organizationalUnitId?: string;
    realEstateStructureId?: string;
  }): Observable<Equipment[]> {
    const params: any = {};
    if (filter.search) params.search = filter.search;
    if (filter.category) params.category = filter.category;
    if (filter.family) params.family = filter.family;
    if (filter.organizationalUnitId) params.organizationalUnitId = filter.organizationalUnitId;
    if (filter.realEstateStructureId) params.realEstateStructureId = filter.realEstateStructureId;
    return this.http
      .get<any[]>(`${environment.apiUrl}/equipments`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(Equipment).map(d))));
  }

  public list({inspectionTypeId}: {inspectionTypeId: string}): Observable<Equipment[]> {
    const params: any = {};
    if (inspectionTypeId) {
      params.inspectionTypeId = inspectionTypeId;
    }
    return this.http
      .get<any[]>(`${environment.apiUrl}/equipments`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(Equipment).map(d))));
  }

  public update(id: string, data: any): Observable<Equipment> {
    return this.http
      .patch<any>(id ? `${environment.apiUrl}/equipments/${id}` : `${environment.apiUrl}/equipments`, data)
      .pipe(map(data => new ModelMapper(Equipment).map(data)));
  }

  public setArchived(
    id: string,
    archived: boolean
  ): Observable<{
    archived: boolean;
    archivedChangedAt: Moment;
    archivedChangedBy: EmbededUser;
  }> {
    return this.http
      .patch<any>(`${environment.apiUrl}/equipments/${id}/archived`, {
        archived,
      })
      .pipe(
        map(data => ({
          archived: data.archived,
          archivedChangedAt: data.archivedChangedAt ? moment(data.archivedChangedAt) : null,
          archivedChangedBy: data.archivedChangedBy ? new ModelMapper(EmbededUser).map(data.archivedChangedBy) : null,
        }))
      );
  }

  public updateMany(data: {equipments?: any[]; toRemove?: string[]}): Observable<Equipment[]> {
    return this.http
      .patch<any>(`${environment.apiUrl}/equipments`, data)
      .pipe(map(data => data.map(d => new ModelMapper(Equipment).map(d))));
  }

  public getNextReferenceIndex(prefix: string, id?: string): Observable<number> {
    const params: any = {prefix};
    if (id) params.id = id;
    return this.http.get<number>(`${environment.apiUrl}/equipments/next-reference-index`, {params});
  }

  public referenceExists(reference: string): Observable<string> {
    const params: any = {reference};
    return this.http.get<string>(`${environment.apiUrl}/equipments/reference-exists`, {params});
  }

  public advancedSearchItems(search: string): Observable<{kind: string; value: string}[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/equipments/advanced-search-items`, {params: {search}});
  }

  public options(category: string, organizationalUnitId: string, name: string): Observable<Option[]> {
    const params: any = {category, name};
    if (organizationalUnitId) {
      params.organizationalUnitId = organizationalUnitId;
    }
    return this.http
      .get<any[]>(`${environment.apiUrl}/equipments/options`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(Option).map(d))));
  }

  public getMapInfo(search: IEquipmentFilter): Observable<
    {
      _id: string;
      coordinates: [number, number];
      status: string;
      inspectionSate: string;
      family: Family;
      category: EquipmentKindCategory;
      group: string;
    }[]
  > {
    return this.http.post<any[]>(`${environment.apiUrl}/equipments/get-map-info`, this.buildSearchFilter(search));
  }

  public info(search: IEquipmentInfoFilter): Observable<{label: string; value: number}[]> {
    return this.http.post<any[]>(`${environment.apiUrl}/equipments/get-info`, this.buildInfoSearchFilter(search));
  }

  public getInspections(id: string): Observable<Inspection[]> {
    return this.http
      .get<Inspection[]>(`${environment.apiUrl}/equipments/${id}/inspections`)
      .pipe(map(data => data.map(d => new ModelMapper(Inspection).map(d))));
  }

  public infoJanitor(headerSearch: IHeaderSearch): Observable<IJanitorInfo[]> {
    const params: any = {};
    return this.http.post<any>(
      `${environment.apiUrl}/equipments/get-info-janitor`,
      buildHeaderSearchFilter(headerSearch),
      {params}
    );
  }

  public count(
    search: IEquipmentFilter
  ): Observable<{family: string; category: string; kindId: string; count: number}[]> {
    const filter = this.buildSearchFilter(search);
    const id = hash(filter);
    if (this.counts[id]) {
      return this.counts[id];
    }
    this.counts[id] = this.http
      .post<any>(`${environment.apiUrl}/equipments/get-count`, filter)
      .pipe(share())
      .pipe(
        tap(() => (this.counts[id] = null)),
        shareReplay(),
        publish()
      ) as ConnectableObservable<{family: string; category: string; kindId: string; count: number}[]>;
    this.counts[id].connect();
    return this.counts[id];
  }

  public create(data: any): Observable<Equipment> {
    return this.http
      .post<any>(`${environment.apiUrl}/equipments`, data)
      .pipe(map(res => new ModelMapper(Equipment).map(res)));
  }

  public datatable(
    query: IDatatableOptions,
    search?: IEquipmentFilter,
    kind?: 'favorites' | 'follow' | EquipmentKindCategory,
    box?: number[][],
    administration?: boolean
  ): Observable<IDatatableRecords<any>> {
    const params: any = {};
    if (box?.length) params.box = box;
    return this.http.post<any>(
      `${environment.apiUrl}/equipments/datatable`,
      {
        query,
        kind,
        filter: this.buildSearchFilter(search),
        administration,
      },
      {params}
    );
  }

  public getStatus(search: IEquipmentFilter): Observable<{[status: string]: number}> {
    return this.http.post<any>(`${environment.apiUrl}/equipments/get-status`, this.buildSearchFilter(search));
  }

  public getInspectionStates(search: IEquipmentFilter): Observable<{name: string; value: number}[]> {
    return this.http.post<any>(
      `${environment.apiUrl}/equipments/get-inspection-states`,
      this.buildSearchFilter(search)
    );
  }

  public getInspectedThisYear(search: IEquipmentFilter): Observable<{count: number; total: number}> {
    return this.http.post<any>(
      `${environment.apiUrl}/equipments/get-inspected-this-year`,
      this.buildSearchFilter(search)
    );
  }

  public countByContractor(search: IEquipmentFilter): Observable<AggregationValue[]> {
    return this.http.post<any[]>(
      `${environment.apiUrl}/equipments/get-count-by-contractor`,
      this.buildSearchFilter(search)
    );
  }

  public getFamily(search: IEquipmentFilter): Observable<{[key: string]: number}> {
    return this.http.post<any>(`${environment.apiUrl}/equipments/get-family`, this.buildSearchFilter(search));
  }

  public getCategory(search: IEquipmentFilter): Observable<{[key: string]: number}> {
    return this.http.post<any>(`${environment.apiUrl}/equipments/get-category`, this.buildSearchFilter(search));
  }

  public getStatusByFamily(search: IEquipmentFilter): Observable<
    {
      family: Family;
      count: number;
      statuses: {status: string; count: number}[];
    }[]
  > {
    return this.http.post<any>(`${environment.apiUrl}/equipments/get-status-by-family`, this.buildSearchFilter(search));
  }

  public getStatusByCategory(search: IEquipmentFilter): Observable<
    {
      family: Family;
      category: EquipmentKindCategory;
      count: number;
      statuses: {status: string; count: number}[];
    }[]
  > {
    return this.http.post<any>(
      `${environment.apiUrl}/equipments/get-status-by-category`,
      this.buildSearchFilter(search)
    );
  }

  public treemap(families?: Family[]): Observable<any[]> {
    const params: any = {};
    if (families) params.families = families;
    return this.http.get<any[]>(`${environment.apiUrl}/equipments/treemap`, {params});
  }

  public getFailureRate(search: IEquipmentFilter): Observable<{sum: number; value: number; count: number}> {
    return this.http.post<any>(`${environment.apiUrl}/equipments/get-failure-rate`, this.buildSearchFilter(search));
  }

  public getAvailabilityRate(search: IEquipmentFilter): Observable<{sum: number; value: number; count: number}> {
    return this.http.post<any>(
      `${environment.apiUrl}/equipments/get-availability-rate`,
      this.buildSearchFilter(search)
    );
  }

  public getContractors(search: IEquipmentFilter): Observable<{name: string; value: string}[]> {
    return this.http.post<any[]>(`${environment.apiUrl}/equipments/contractors`, this.buildSearchFilter(search));
  }

  public async getSate(search: IEquipmentFilter): Promise<number> {
    return lastValueFrom(
      this.http.post<number>(`${environment.apiUrl}/equipments/get-state`, this.buildSearchFilter(search))
    );
  }

  public createControl(id: string, name: string): Observable<EquipmentControl> {
    return this.http
      .post<any>(`${environment.apiUrl}/equipments/${id}/controls`, {name})
      .pipe(map(data => new ModelMapper(EquipmentControl).map(data)));
  }

  public deleteControl(id: string, controlId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}/equipments/${id}/controls/${controlId}`);
  }

  public createControlOption(id: string, controlId: string, name: string): Observable<boolean> {
    return this.http.post<any>(`${environment.apiUrl}/equipments/${id}/controls/${controlId}`, {name});
  }

  public deleteControlOption(id: string, controlId: string, name: string): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}/equipments/${id}/controls/${controlId}/options/${name}`);
  }

  private buildSearchFilter(search: IEquipmentFilter): any {
    const filter = buildHeaderSearchFilter(search);
    if (search?.statuses) filter.statuses = search.statuses;
    if (search?.categories) filter.categories = search.categories;
    if (search?.families) filter.families = search.families;
    if (search?.contractorIds?.length) filter.contractorIds = search.contractorIds;
    if (search?.equipmentKindIds?.length) filter.equipmentKindIds = search.equipmentKindIds;
    if (!isNil(search?.supervision)) filter.supervision = search.supervision;
    if (!isNil(search?.hypervision)) filter.hypervision = search.hypervision;
    if (search?.realEstateStructureId) filter.realEstateStructureId = search.realEstateStructureId;
    if (search?.equipmentId) filter.equipmentId = search.equipmentId;
    if (search?.equipmentKindId) filter.equipmentKindId = search.equipmentKindId;
    if (search?.equipmentKind) filter.equipmentKindId = search.equipmentKind.id;
    if (search?.signatureSettingName) filter.signatureSettingName = search.signatureSettingName;
    if (!isNil(search?.isTertiary)) filter.isTertiary = search.isTertiary;
    if (!isNil(search?.supervised)) filter.supervised = search.supervised;
    return filter;
  }

  private buildInfoSearchFilter(search: IEquipmentInfoFilter): any {
    const filter = this.buildSearchFilter(search);
    filter.fields = search.fields;
    return filter;
  }
}
