/** @format */

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {ChartModule} from '../chart/chart.module';
import {EquipmentStatusHistoryChartComponent} from './equipment-status-history-chart.component';

@NgModule({
  declarations: [EquipmentStatusHistoryChartComponent],
  imports: [CommonModule, ChartModule],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
  exports: [EquipmentStatusHistoryChartComponent],
})
export class EquipmentStatusHistoryChartModule {}
