/** @format */

import {propertyMap} from 'model-mapper';
import moment from 'moment';
import {EmbededContractor} from './embeded-contractor';
import {EsiMetrics} from './esi-metrics';
import {Location} from './location';
import {EmbededUser} from './user';

export interface IOrganizationalUnitInfo {
  ou: {[kind: string]: number};
  eq: {[kind: string]: number};
  boilerRooms: number;
}

export enum OrganizationalUnitKind {
  CLUSTER = 'cluster',
  DIVISION = 'division',
  AGENCY = 'agency',
  GROUP = 'group',
}

export const OrganizationalUnitKindName = {
  [OrganizationalUnitKind.CLUSTER]: 'Groupe RIVP',
  [OrganizationalUnitKind.DIVISION]: 'Division',
  [OrganizationalUnitKind.AGENCY]: 'Agence',
  [OrganizationalUnitKind.GROUP]: 'Groupe',
};

export class Layer {
  @propertyMap()
  public color: string;

  @propertyMap()
  public geojson: any;
}

export class OrganizationalUnit {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap()
  public name: string;

  @propertyMap()
  public code: string;

  @propertyMap()
  public kind: OrganizationalUnitKind;

  @propertyMap()
  public parent: string;

  @propertyMap({type: 'Moment'})
  public createdAt: moment.Moment;

  @propertyMap({type: 'Moment'})
  public updatedAt: moment.Moment;

  @propertyMap()
  public path: string;

  @propertyMap()
  public pathNames: string[];

  @propertyMap({type: Location})
  public location: Location;

  @propertyMap({type: Layer})
  public layer: Layer;

  @propertyMap({type: EsiMetrics})
  public metrics: EsiMetrics;

  @propertyMap()
  public metadata: any;

  @propertyMap({type: [EmbededContractor]})
  public contractors: EmbededContractor[];

  @propertyMap({type: EmbededUser})
  public manager: EmbededUser;

  @propertyMap({type: EmbededUser})
  public assistant: EmbededUser;

  @propertyMap({type: EmbededUser})
  public janitor: EmbededUser;
}

export class MapInfoOrganizationalUnit {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap()
  public kind: OrganizationalUnitKind;

  @propertyMap()
  public name: string;

  @propertyMap({source: 'location.coordinates'})
  public coordinates: [number, number];

  @propertyMap({source: 'location.address.formatted'})
  public address: string;

  @propertyMap({type: EsiMetrics})
  public metrics: EsiMetrics;

  @propertyMap({type: Layer})
  public layer: Layer;

  @propertyMap()
  public pathNames: string[];
}
