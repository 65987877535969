<app-page-tracking page="Espace Énergie & Fluide > Chaufferie > Statistiques"></app-page-tracking>

<div id="container">
  <mat-tab-group (selectedTabChange)="tab = $event.index">

    <mat-tab [label]="'charts' | translate">
      <ng-scrollbar>

        <div class="content" fxLayout="column" fxLayoutGap="16px">

          <div id="weather-info" fxLayoutAlign="start start" fxLayoutGap="16px">

            <mat-card id="outdoor-data" fxLayout="column" fxLayoutGap="10px" fxFlex="50">
              <div class="title" fxLayoutAlign="start center" fxLayoutGap="10px">
                <ion-icon color="accent" name="sunny"></ion-icon>
                <ion-label color="primary" translate>outdoor-data</ion-label>
                <button *ngIf="!forcast" id="forcast" mat-button color="primary" (click)="forcast = true">
                  <mat-label translate>see-forcast</mat-label>
                </button>
                <button *ngIf="forcast" id="forcast" mat-icon-button color="danger" (click)="forcast = false">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <div fxLayout="column" fxLayoutGap="10px">

                <div id="outdoor-temperature" fxLayoutAlign="space-evently center" fxLayoutGap="10px">

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <div fxLayoutAlign="space-between center">
                      <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>temperature-min
                      </ion-label>
                    </div>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.outdoor?.weatherTemperature?.min, colors: colors.temperature, type: 'weather', decimal: 1 }">
                      </ng-container>
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.outdoor?.trendTemperature?.min, colors: colors.temperature, type: 'trend', decimal: 1 }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-celcius</ion-label>
                  </mat-card>

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>temperature-avg
                    </ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.outdoor?.weatherTemperature?.avg, colors: colors.temperature, type: 'weather', decimal: 1 }">
                      </ng-container>
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.outdoor?.trendTemperature?.avg, colors: colors.temperature, type: 'trend', decimal: 1 }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-celcius</ion-label>
                  </mat-card>

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>temperature-max
                    </ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.outdoor?.weatherTemperature?.max, colors: colors.temperature, type: 'weather', decimal: 1 }">
                      </ng-container>
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.outdoor?.trendTemperature?.max, colors: colors.temperature, type: 'trend', decimal: 1 }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-celcius</ion-label>
                  </mat-card>

                </div>

                <div id="outdoor-humidity" fxLayoutAlign="space-evently center" fxLayoutGap="10px">

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>humidity-min</ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.outdoor?.humidity?.min, colors: colors.humidity }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-percent</ion-label>
                  </mat-card>

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>humidity-avg</ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.outdoor?.humidity?.avg, colors: colors.humidity }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-percent</ion-label>
                  </mat-card>

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>humidity-max</ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.outdoor?.humidity?.max, colors: colors.humidity }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-percent</ion-label>
                  </mat-card>

                </div>

                <div fxLayoutAlign="space-evently center" fxLayoutGap="10px">

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>wind</ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.outdoor?.wind?.speed, type: 'speed' }">
                      </ng-container>
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.outdoor?.wind?.gust, type: 'gusts' }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-kmh</ion-label>
                  </mat-card>

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>precipitation
                    </ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.outdoor?.precipitation }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-mm</ion-label>
                  </mat-card>

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>sunshine</ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ion-label class="value" fxLayoutAlign="center center" color="secondary">
                        <ion-text [style.font-weight]="500"
                          [countUp]="weatherInfo?.outdoor?.sunhour ? (weatherInfo?.outdoor?.sunhour | floor) : 0"
                          [options]="{ duration: 1, separator: ' ', decimal: ',', decimalPlaces: 0, suffix:'h' }"
                          [reanimateOnClick]="false">
                        </ion-text>
                        <ion-text [style.font-weight]="500"
                          [countUp]="weatherInfo?.outdoor?.sunhour ? ((weatherInfo?.outdoor?.sunhour - (weatherInfo?.outdoor?.sunhour | floor)) * 60) : 0"
                          [options]="{ duration: 1, separator: ' ', decimal: ',', decimalPlaces: 0, suffix:'mn' }"
                          [reanimateOnClick]="false">
                        </ion-text>
                      </ion-label>
                    </div>
                    <ion-label class="unit" [style.visibility]="'hidden'">-</ion-label>
                  </mat-card>

                </div>

                <mat-card id="outdoor-air-quality" class="block" fxLayout="column" fxLayoutAlign="center center"
                  fxLayoutGap="8px">
                  <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                    <div class="elmt" fxLayoutAlign="end center" fxLayoutGap="4px">
                      <ion-label [innerHTML]="'air-quality-index' | translate"></ion-label>
                      <ion-icon name="stop"
                        [style.color]="weatherInfo?.outdoor?.airQuality?.index | numberMatch:colors.quality"></ion-icon>
                      <ion-label class="elmt-value" translate>
                        {{qualities[weatherInfo?.outdoor?.airQuality?.index - 1]}}</ion-label>
                    </div>
                    <div class="elmt" fxLayoutAlign="end center" fxLayoutGap="4px">
                      <ion-label [innerHTML]="'air-quality-o3' | translate"></ion-label>
                      <ion-icon name="stop"
                        [style.color]="weatherInfo?.outdoor?.airQuality?.o3 | numberMatch:colors.quality"></ion-icon>
                      <ion-label class="elmt-value" translate>
                        {{qualities[weatherInfo?.outdoor?.airQuality?.o3 - 1]}}</ion-label>
                    </div>
                    <div class="elmt" fxLayoutAlign="end center" fxLayoutGap="4px">
                      <ion-label [innerHTML]="'air-quality-no2' | translate"></ion-label>
                      <ion-icon name="stop"
                        [style.color]="weatherInfo?.outdoor?.airQuality?.no2 | numberMatch:colors.quality"></ion-icon>
                      <ion-label class="elmt-value" translate>
                        {{qualities[weatherInfo?.outdoor?.airQuality?.no2 - 1]}}</ion-label>
                    </div>
                    <div class="elmt" fxLayoutAlign="end center" fxLayoutGap="4px">
                      <ion-label [innerHTML]="'air-quality-pm10' | translate"></ion-label>
                      <ion-icon name="stop"
                        [style.color]="weatherInfo?.outdoor?.airQuality?.pm10 | numberMatch:colors.quality">
                      </ion-icon>
                      <ion-label class="elmt-value" translate>
                        {{qualities[weatherInfo?.outdoor?.airQuality?.pm10 - 1]}}</ion-label>
                    </div>
                    <div class="elmt" fxLayoutAlign="end center" fxLayoutGap="4px">
                      <ion-label [innerHTML]="'air-quality-pm25' | translate"></ion-label>
                      <ion-icon name="stop"
                        [style.color]="weatherInfo?.outdoor?.airQuality?.pm25 | numberMatch:colors.quality">
                      </ion-icon>
                      <ion-label class="elmt-value" translate>
                        {{qualities[weatherInfo?.outdoor?.airQuality?.pm25 - 1]}}</ion-label>
                    </div>
                    <div class="elmt" fxLayoutAlign="end center" fxLayoutGap="4px">
                      <ion-label [innerHTML]="'air-quality-so2' | translate"></ion-label>
                      <ion-icon name="stop"
                        [style.color]="weatherInfo?.outdoor?.airQuality?.so2 | numberMatch:colors.quality"></ion-icon>
                      <ion-label class="elmt-value" translate>
                        {{qualities[weatherInfo?.outdoor?.airQuality?.so2 - 1]}}</ion-label>
                    </div>
                  </div>
                  <div id="outdoor-air-quality-legend" fxLayoutAlign="space-between center" fxLayoutGap="8px"
                    [style.width.%]="100">
                    <div *ngFor="let q of qualities; index as index" fxLayoutAlign="start center" fxLayoutGap="4px">
                      <ion-icon name="stop" [style.color]="(index + 1)| numberMatch:colors.quality">
                      </ion-icon>
                      <ion-label translate>{{q}}</ion-label>
                    </div>
                  </div>
                </mat-card>

              </div>
            </mat-card>
            <ion-backdrop *ngIf="forcast" [style.opacity]="0.2"></ion-backdrop>

            <mat-card fxLayout="column" fxLayoutGap="10px" fxFlex="50">
              <div class="title" fxLayoutAlign="start center" fxLayoutGap="10px">
                <ion-icon color="secondary" name="home"></ion-icon>
                <ion-label color="primary" translate>indoor-data</ion-label>
              </div>
              <div fxLayout="column" fxLayoutGap="10px">

                <div id="indoor-temperature" fxLayoutAlign="space-evently center" fxLayoutGap="10px">

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>temperature-min
                    </ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.indoor?.temperature?.min, colors: colors.temperature, decimal: 1 }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-celcius</ion-label>
                  </mat-card>

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>temperature-avg
                    </ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.indoor?.temperature?.avg, colors: colors.temperature, decimal: 1 }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-celcius</ion-label>
                  </mat-card>

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>temperature-max
                    </ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.indoor?.temperature?.max, colors: colors.temperature, decimal: 1 }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-celcius</ion-label>
                  </mat-card>

                </div>

                <div id="indoor-humidity" fxLayoutAlign="space-evently center" fxLayoutGap="10px">

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>humidity-min</ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.indoor?.humidity?.min, colors: colors.humidity }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-percent</ion-label>
                  </mat-card>

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>humidity-avg</ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.indoor?.humidity?.avg, colors: colors.humidity }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-percent</ion-label>
                  </mat-card>

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>humidity-max</ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.indoor?.humidity?.max, colors: colors.humidity }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-percent</ion-label>
                  </mat-card>

                </div>

                <div id="air-quality-indoor" fxLayoutAlign="space-evently center" fxLayoutGap="10px">

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>air-quality-min
                    </ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.indoor?.airQuality?.min, colors: colors.airQuality }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-ppm</ion-label>
                  </mat-card>

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>air-quality-avg
                    </ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.indoor?.airQuality?.avg, colors: colors.airQuality }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-ppm</ion-label>
                  </mat-card>

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>air-quality-max
                    </ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ng-container
                        *ngTemplateOutlet="valueTmpl; context: { value: weatherInfo?.indoor?.airQuality?.max, colors: colors.airQuality }">
                      </ng-container>
                    </div>
                    <ion-label class="unit" translate>in-ppm</ion-label>
                  </mat-card>

                </div>

                <div id="dju" fxLayoutAlign="center center" fxLayoutGap="10px">

                  <mat-card class="block" fxLayout="column" fxLayoutAlign="space-between center"
                    [style.width]="'calc(33% - 8px)'">
                    <ion-label class="title" capitalized [style.margin-bottom.px]="8" translate>dju-with-acronym
                    </ion-label>
                    <div class="block-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                      [style.width.%]="100">
                      <ion-label class="value" fxLayoutAlign="center center">
                        <ion-text class="type" translate>dju-heating</ion-text>
                        <ion-text [style.font-weight]="500" color="secondary"
                          [countUp]="weatherInfo?.indoor?.dju ? weatherInfo?.indoor?.dju : 0"
                          [options]="{ duration: 1, separator: ' ' }" [reanimateOnClick]="false">
                        </ion-text>
                        <ion-text class="suffix" translate>dju-heating-unit</ion-text>
                      </ion-label>
                      <ion-label class="value" fxLayoutAlign="center center">
                        <ion-text class="type" translate>dju-cold</ion-text>
                        <ion-text [style.font-weight]="500" color="dark" [countUp]="0"
                          [options]="{ duration: 1, separator: ' ' }" [reanimateOnClick]="false">
                        </ion-text>
                        <ion-text class="suffix" translate>dju-cold-unit</ion-text>
                      </ion-label>
                    </div>
                    <ion-label class="unit" translate [style.visibility]="'hidden'">-</ion-label>
                  </mat-card>

                </div>

              </div>
            </mat-card>

          </div>

          <div fxLayoutAlign="start center" fxLayoutGap="16px">
            <app-weather-chart id="weather-chart" [range]="range" class="chart" fxFlex="50">
            </app-weather-chart>
            <app-sensor-chart id="wind-chart" [realEstateStructureId]="data.id" [range]="range" class="chart"
              fxFlex="50">
            </app-sensor-chart>
          </div>

          <app-primary-chart id="primary-chart" [realEstateStructureId]="data.id" [range]="range" class="chart"
            fxFlex="100">
          </app-primary-chart>

          <div fxLayoutAlign="start center" fxLayoutGap="16px">
            <app-ecs-chart id="ecs-chart" [realEstateStructureId]="data.id" [range]="range" class="chart" fxFlex="50">
            </app-ecs-chart>
            <app-heating-chart id="heating-chart" [realEstateStructureId]="data.id" [range]="range" class="chart"
              fxFlex="50">
            </app-heating-chart>
          </div>

        </div>

      </ng-scrollbar>
    </mat-tab>

    <mat-tab [label]="'datas' | translate">
      <ng-template matTabContent>
        <div class="content datagrid">
          <app-datagrid id="datagrid" [options]="options">
            <sesio-loader datagrid-spinner></sesio-loader>
          </app-datagrid>
        </div>
      </ng-template>
    </mat-tab>

  </mat-tab-group>

  <div *ngIf="tab === 0" @fade id="range-selector" fxLayoutAlign="start center" fxLayoutGap="16px">

    <mat-button-toggle-group #group="matButtonToggleGroup" [value]="selectedZoom" (change)="changeZoom($event.value)">
      <mat-button-toggle *ngFor="let zoom of zooms" [checked]="zoom === selectedZoom" [value]="zoom"
        [aria-label]="zoom | translate">
        <mat-label translate>{{zoom}}</mat-label>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-form-field>
      <mat-label translate>period</mat-label>
      <mat-date-range-input [rangePicker]="picker" [formGroup]="range">
        <input matStartDate formControlName="start" [placeholder]="'start-date' | translate">
        <input matEndDate formControlName="end" [placeholder]="'end-date' | translate">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>

</div>

<ng-template #valueTmpl let-value="value" let-colors="colors" let-type="type" let-decimal="decimal"
  let-separator="separator" let-suffix="suffix">
  <ion-label class="value" fxLayoutAlign="center center"
    [style.color]="colors ? (value | numberMatch: colors) : 'var(--ion-color-secondary)'">
    <ion-text class="type" translate>{{type}}</ion-text>
    <ion-text [style.font-weight]="500" [countUp]="value ? value : 0"
      [options]="{ duration: 1, separator: ' ', decimal: (separator || ','), decimalPlaces: decimal, suffix: (suffix || '') }"
      [reanimateOnClick]="false">
    </ion-text>
  </ion-label>
</ng-template>