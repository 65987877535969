/** @format */

import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpProgressEvent} from '@angular/common/http';
import {v4} from 'uuid';
import {LibConfig, LibConfigService} from '../config.service';
import {last, map, mergeMap, tap} from 'rxjs/operators';
import {ModelMapper} from 'model-mapper';
import {File as SesioFile} from '../classes/file';
import {merge} from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public delete(prefix: string, id?: string): Observable<boolean> {
    const params: any = {prefix};
    if (id) params.id = id;
    return this.http.delete<boolean>(`${this.config.environment.apiUrl}/files`, {params});
  }

  public upload(
    prefix: string,
    file: File,
    info?: {
      kind?: string;
      title?: string;
      description?: string;
    },
    progress?: (progress: number) => void
  ): Observable<SesioFile> {
    const id = v4();
    return this.requestUploadUrl(`${prefix}/${id}`, file).pipe(
      mergeMap(url =>
        this.http
          .put<any>(url, file, {
            reportProgress: true,
            observe: 'events',
            headers: {'Content-Type': file.type},
          })
          .pipe(
            tap((event: HttpProgressEvent) =>
              progress
                ? progress((event as any).type === 0 ? 0 : event.type !== 1 ? 1 : event.loaded / event.total)
                : null
            )
          )
      ),
      last(),
      map(() =>
        new ModelMapper(SesioFile).map(
          merge(info, {
            filename: file.name,
            path: `${prefix}/${id}/${file.name}`,
            size: file.size,
            mimetypes: [file.type],
            date: file.lastModified,
          })
        )
      )
    );
  }

  private requestUploadUrl(prefix: string, file: File): Observable<string> {
    const data: any = {prefix, name: file.name, type: file.type};
    return this.http.post(`${this.config.environment.apiUrl}/files/get-upload-url`, data, {responseType: 'text'});
  }
}
