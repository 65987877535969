/** @format */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SensorService as SesioSensorService, ISensorFilter} from 'sesio-lib';
import {environment} from '../../environments/environment';
import {IDatatableOptions, IDatatableRecords} from '../_components/datagrid/datatable.class';

@Injectable({
  providedIn: 'root',
})
export class SensorService extends SesioSensorService {
  public datatable(query: IDatatableOptions, filter?: ISensorFilter): Observable<IDatatableRecords<any>> {
    return this.http.post<any>(`${environment.apiUrl}/sensors/datatable`, {query, filter: this.buildFilter(filter)});
  }
}
