/** @format */

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {File} from '../classes/file';
import {ISearchResult} from '../classes/search-result';
import {LibConfig, LibConfigService} from '../config.service';

export interface IUserAnomalyReportFilter {
  natureId?: string;
  organizationalUnitId?: string;
  realEstateStructureId?: string;
  equipmentId?: string;
}

export interface IUserAnomalyReportUpsert {
  natureId: string;
  organizationalUnitId?: string;
  realEstateStructureId?: string;
  equipmentId?: string;
  picture?: File;
  video?: File;
  comment?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserAnomalyReportService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public find(
    options: {fields: string; limit?: number; start?: number},
    filter?: IUserAnomalyReportFilter
  ): Promise<ISearchResult> {
    const query: any = {draw: Date.now().toString(), start: 0, columns: [], order: []};
    options.fields.split(' ').forEach((f, i) => {
      if (f.startsWith('-')) {
        query.order.push({column: i, dir: 'desc'});
        f = f.slice(1);
      } else if (f.startsWith('+')) {
        query.order.push({column: i, dir: 'asc'});
        f = f.slice(1);
      }
      query.columns.push({data: f, name: f, searchable: true});
    });
    if (options.limit) query.length = options.limit;
    if (options.start) query.start = options.start;
    return lastValueFrom(
      this.http.post<any>(`${this.config.environment.apiUrl}/user-anomaly-reports/datatable`, {query, filter})
    );
  }

  public upsert(data: IUserAnomalyReportUpsert): Promise<boolean> {
    console.warn('upsert', data);
    return lastValueFrom(this.http.post<boolean>(`${this.config.environment.apiUrl}/user-anomaly-reports`, data));
  }

  protected buildSearchFilter(search: IUserAnomalyReportFilter): any {
    const filter: any = {};
    if (search?.natureId) filter.natureId = search.natureId;
    if (search?.equipmentId) filter.equipmentId = search.equipmentId;
    if (search?.organizationalUnitId) filter.organizationalUnitId = search.organizationalUnitId;
    if (search?.realEstateStructureId) filter.realEstateStructureId = search.realEstateStructureId;
    return filter;
  }
}
