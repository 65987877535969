/** @format */

import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {cloneDeep, each, find, get, indexOf, isEqual, map, max, merge, min, pick, reduce, sum, values} from 'lodash-es';
import moment, {Moment} from 'moment';
import 'moment-timezone';
import {lastValueFrom} from 'rxjs';

import {AggregationValue} from '../../classes/aggregation';
import {
  EquipmentStatusColor,
  SignatureFlowState,
  SignatureFlowStateOptions,
  SignatureStatus,
  SignatureStatusColor,
  SignatureStatusOptions,
} from '../../classes/equipment';
import {EquipmentStatusHistoryField} from '../../classes/equipment-status-history';
import {EquipmentStatusHistoryService} from '../../services/equipment-status-history.service';
import {EquipmentService} from '../../services/equipment.service';
import {ChartComponent} from '../chart/chart/chart.component';

@Component({
  selector: 'app-equipment-status-history-chart',
  templateUrl: './equipment-status-history-chart.component.html',
  styleUrls: ['./equipment-status-history-chart.component.scss'],
})
export class EquipmentStatusHistoryChartComponent implements OnInit {
  @Input()
  id: string;

  @Input('filter')
  public set setFilter(filter: any) {
    if (!isEqual(this.filter, filter)) {
      this.filter = cloneDeep(filter);
      if (this.chart) this.loadData();
    }
  }
  private filter: any;

  private chart: ChartComponent;

  ganttOptions = {
    chart: {zoomType: 'x'},
    credits: {enabled: false},
    exporting: {enabled: false},
    title: {text: ''},
    xAxis: [
      {
        dateTimeLabelFormats: {
          week: {list: ['Semaine %W', 'S%W']},
        },
      },
      {
        dateTimeLabelFormats: {
          week: {list: ['Semaine %W', 'S%W']},
        },
      },
    ],
    yAxis: {
      type: 'category',
      categories: values(EquipmentStatusHistoryField),
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return `
          <span style="font-size: 1.2em; color:${this.point.color};">${this.point.name}</span></br>
        <span style="color:${this.point.color};">${moment
          .duration(moment(this.point.dateEnd).diff(this.point.dateStart))
          .humanize()}</span></br>
        <span>${moment(this.point.dateStart).format('L LT')}</span> - <span>${moment(this.point.dateEnd).format(
          'L LT'
        )}</span>
        `;
      },
    },
    navigator: {
      enabled: true,
      liveRedraw: true,
      series: {
        type: 'gantt',
        pointPlacement: 0.5,
        pointPadding: 0.25,
      },
      yAxis: {
        min: 0,
        max: 3,
        reversed: true,
        categories: [],
        label: {enabled: false},
      },
    },
    scrollbar: {
      enabled: true,
    },
    rangeSelector: {
      enabled: false,
      selected: 0,
    },
  };

  lineOptions = {
    chart: {zoomType: 'x'},
    credits: {enabled: false},
    exporting: {enabled: false},
    title: {text: ''},
    rangeSelector: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      split: false,
      useHTML: true,
    },
    legend: {
      enabled: true,
    },
    xAxis: {ordinal: false},
    yAxis: [
      {
        showLastLabel: true,
        opposite: false,
        lineColor: '#e9396b',
        labels: {style: {color: '#e9396b'}},
        lineWidth: 1,
        resize: {enabled: true},
        min: 0,
      },
      {
        showLastLabel: true,
        opposite: true,
        lineColor: 'rgba(240, 119, 153, 0.50)',
        labels: {style: {color: 'rgba(240, 119, 153, 0.50)'}},
        lineWidth: 1,
        resize: {enabled: true},
        min: 0,
        tooltipValueFormat: '{value:.0f}',
      },
    ],
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false,
        },
      },
    },
  };
  options: any;
  kind: string;
  private extremes: {start?: Moment; end?: Moment};

  constructor(
    private equipmentStatusHistoryService: EquipmentStatusHistoryService,
    private equipmentService: EquipmentService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.kind = this.id ? 'gantt' : 'highstock';
    this.options = this.id ? this.ganttOptions : this.lineOptions;
  }

  public setExtremes(start?: Moment, end?: Moment) {
    this.extremes = {start, end};
    if (this.chart) {
      if (this.id) this.loadData(this.chart);
      else {
        if (!this.id && this.extremes.end && this.extremes.start) {
          this.loadData();
        } else {
          this.chart.setExtremes(this.extremes.start, this.extremes.end, true);
        }
      }
    }
  }

  private loadingDataTm;
  async loadData(chartComponent?: ChartComponent): Promise<void> {
    if (!this.chart) this.chart = chartComponent;
    this.chart.setLoading(true);
    if (this.extremes) {
      this.chart.setExtremes(this.extremes.start, this.extremes.end, true);
    }
    if (this.loadingDataTm) clearTimeout(this.loadingDataTm);
    this.loadingDataTm = setTimeout(async () => {
      this.loadingDataTm = null;
      const series: any[] = await (this.id ? this.loadGanttChartData(this.chart) : this.loadChartData());
      if (series) this.chart.setSeries(series);
      this.chart.setLoading(false);
    }, 300);
  }

  private async loadGanttChartData(chartComponent: ChartComponent): Promise<any[]> {
    return [
      {
        data: await this.equipmentStatusHistoryService
          .getEquipmentStatuses(this.id, {
            start: this.extremes?.start,
            end: this.extremes?.end,
          })
          .toPromise()
          .then(data => {
            const categories = Array.from(
              reduce(
                data,
                (pv, cv) => (pv.add(this.translate.instant(`equipment-status-history_${cv.field}`)), pv),
                new Set<string>()
              )
            );
            chartComponent.setYCategories(categories);
            const formatted = map(data, d => ({
              start: max([d.start.valueOf(), this.extremes?.start?.valueOf()]),
              end: min([d.end?.valueOf() || moment().valueOf(), this.extremes?.end?.valueOf()]),
              dateStart: d.start.valueOf(),
              dateEnd: d.end?.valueOf() || moment().valueOf(),
              name: this.translate.instant(`${d.status}`),
              color: (() => {
                switch (d.field) {
                  case EquipmentStatusHistoryField.STATUS:
                    return EquipmentStatusColor[d.status];
                  case EquipmentStatusHistoryField.SIGNATURES_STATUS:
                    return SignatureStatusColor[d.status];
                  case EquipmentStatusHistoryField.SIGNATURES_STATUS_V2:
                    return SignatureStatusColor[d.status];
                  case EquipmentStatusHistoryField.SIGNATURE_FLOW_STATE:
                    return d.status === SignatureFlowState.NOFLOW
                      ? 'var(--ion-color-noflow)'
                      : 'var(--ion-color-success)';
                  case EquipmentStatusHistoryField.CONTRACTOR_EVENT:
                    return 'var(--ion-color-warning)';
                  case EquipmentStatusHistoryField.RIVP_EVENT:
                    return '#f8c31d';
                  case EquipmentStatusHistoryField.MAINTENANCE:
                    return 'var(--ion-color-secondary)';
                  case EquipmentStatusHistoryField.SUPERVISOR_EVENT:
                    return 'var(--ion-color-warning)';
                }
              })(),
              field: d.field,
              y: indexOf(categories, this.translate.instant(`equipment-status-history_${d.field}`)),
            }));
            return formatted;
          }),
      },
    ];
  }

  private data: {[key: string]: AggregationValue[]};
  private chartDataOptions = [
    merge({y: 0, type: 'line', visible: true}, find(SignatureStatusOptions, {value: SignatureStatus.OFFLINE}), {
      name: 'Pannes totales',
    }),
    merge({y: 0, type: 'line', visible: false}, find(SignatureFlowStateOptions, {value: SignatureFlowState.NOFLOW})),
    {
      y: 1,
      type: 'area',
      visible: true,
      value: 'new-failure',
      name: 'Nouvelles pannes',
      color: 'rgba(240, 119, 153, 0.50)',
    },
  ];
  // private chartDataOptions = concat(
  // filter(SignatureStatusOptions, option => includes([SignatureStatus.OFFLINE], option.value)) as any[],
  // filter(SignatureFlowStateOptions, option => includes([SignatureFlowState.NOFLOW], option.value)) as any[]
  // );
  // private lastGrouping: { unit?: string; binSize?: number };
  private async loadChartData(): Promise<any[]> {
    const grouping: {unit: string; binSize: number} = {unit: 'minute', binSize: 10};
    if (this.extremes.end?.diff(this.extremes.start, 'months') >= 6) {
      grouping.unit = 'day';
      grouping.binSize = 1;
    } else if (this.extremes.end?.diff(this.extremes.start, 'months') >= 3) {
      grouping.unit = 'day';
      grouping.binSize = 1;
    } else if (this.extremes.end?.diff(this.extremes.start, 'months') >= 1) {
      grouping.unit = 'hour';
      grouping.binSize = 1;
    } else if (this.extremes.end?.diff(this.extremes.start, 'weeks') >= 1) {
      grouping.unit = 'hour';
      grouping.binSize = 1;
    } else {
      grouping.unit = 'minute';
      grouping.binSize = 10;
    }
    const total = sum(map(await this.equipmentService.count(this.filter), 'count'));
    each(this.chart.chart.yAxis, axis => (axis.max = total));
    this.data = await Promise.all([
      lastValueFrom(
        this.equipmentStatusHistoryService.getChartRangeData(
          merge(
            {
              start: this.extremes?.start.toDate(),
              end: this.extremes?.end.toDate(),
              field: EquipmentStatusHistoryField.SIGNATURES_STATUS,
              statuses: [SignatureStatus.OFFLINE],
            },
            grouping,
            this.filter
          )
        )
      ),
      lastValueFrom(
        this.equipmentStatusHistoryService.getChartRangeData(
          merge(
            {
              start: this.extremes?.start.toDate(),
              end: this.extremes?.end.toDate(),
              field: EquipmentStatusHistoryField.SIGNATURE_FLOW_STATE,
              statuses: [SignatureFlowState.NOFLOW],
            },
            grouping,
            this.filter
          )
        )
      ),
      lastValueFrom(
        this.equipmentStatusHistoryService.getByUnitData(
          merge(
            {
              start: this.extremes?.start.toDate(),
              end: this.extremes?.end.toDate(),
              field: EquipmentStatusHistoryField.SIGNATURES_STATUS,
              statuses: [SignatureStatus.OFFLINE],
            },
            {unit: grouping.unit === 'minute' ? 'hour' : 'day', binSize: 1},
            this.filter
          )
        )
      ).then(data => ({'new-failure': get(data, SignatureStatus.OFFLINE, [])})),
    ]).then(data => merge(data[0], pick(data[1], SignatureFlowState.NOFLOW), data[2]));
    return map(this.chartDataOptions, option => {
      const values = get(this.data, option.value, []);
      return {
        type: option.type,
        step: true,
        name: option.name,
        yAxis: option.y,
        visible: option.visible,
        // data: map(get(this.data, option.value, []), d => [d.name, d.value]),
        data: reduce(
          values,
          (pv, cv, i) => {
            if (
              (i >= values.length - 1 || values[i + 1].name >= this.extremes.start) &&
              (!i || values[i - 1].name <= this.extremes.end)
            ) {
              pv.push([cv.name, cv.value]);
            }
            return pv;
          },
          []
        ),
        max: total,
        color: option.color,
      };
    });
  }
}
