/** @format */

import {propertyMap} from 'model-mapper';
import {File} from '../classes/file';
import {UserAnomalyReportNature} from './user-anomaly-report-nature';

export enum UserAnomalyReportFileKind {
  PICTURE = 'picture',
  VIDEO = 'video',
}

export class UserAnomalyReportOccurence {
  @propertyMap({type: Date})
  date: Date;

  @propertyMap()
  userId: string;

  @propertyMap({type: File})
  picture: File | null;

  @propertyMap({type: File})
  video: File | null;
}

export class UserAnomalyReport {
  @propertyMap({type: UserAnomalyReportNature})
  nature: UserAnomalyReportNature;

  @propertyMap()
  organizationalUnitId?: string;

  @propertyMap()
  realEstateStructureId?: string;

  @propertyMap()
  equipmentId?: string;

  @propertyMap({type: UserAnomalyReportOccurence})
  occurences: UserAnomalyReportOccurence;

  @propertyMap({type: Date})
  closedAt: Date | null;
}
