/** @format */

export enum Family {
  ELEVATOR = 'elevator',
  PARKING_DOOR = 'parking-door',
  OPTICAL_FIBER = 'optical-fiber',
  RELAY_ANTENNA = 'relay-antenna',
  IOT = 'iot',
  PHOTOVOLTAIC_PANEL = 'photovoltaic-panel',
  ACCESS_CONTROL = 'access-control',
  ENERGY_FLUID = 'energy-fluid',
  FIRE_SAFETY = 'fire-safety',
  CHARGING_STATION = 'charging-station',
  OTHER = 'other',
}

export const FamilyIcon = {
  [Family.ELEVATOR]: `assets/icon/${Family.ELEVATOR}.svg`,
  [Family.PARKING_DOOR]: `assets/icon/${Family.PARKING_DOOR}.svg`,
  [Family.OPTICAL_FIBER]: `assets/icon/${Family.OPTICAL_FIBER}.svg`,
  [Family.RELAY_ANTENNA]: `assets/icon/${Family.RELAY_ANTENNA}.svg`,
  [Family.IOT]: `assets/icon/${Family.IOT}.svg`,
  [Family.PHOTOVOLTAIC_PANEL]: `assets/icon/${Family.PHOTOVOLTAIC_PANEL}.svg`,
  [Family.ACCESS_CONTROL]: `assets/icon/${Family.ACCESS_CONTROL}.svg`,
  [Family.ENERGY_FLUID]: `assets/icon/${Family.ENERGY_FLUID}.svg`,
  [Family.FIRE_SAFETY]: `assets/icon/${Family.FIRE_SAFETY}.svg`,
  [Family.CHARGING_STATION]: `assets/icon/${Family.CHARGING_STATION}.svg`,
  [Family.OTHER]: `assets/icon/${Family.OTHER}.svg`,
};

export const FamilyName = {
  [Family.ELEVATOR]: 'Ascenseur',
  [Family.PARKING_DOOR]: 'Porte de parking',
  [Family.OPTICAL_FIBER]: 'Fibre optique',
  [Family.RELAY_ANTENNA]: 'Antenne relais',
  [Family.IOT]: 'Object connecté',
  [Family.PHOTOVOLTAIC_PANEL]: 'Panneau photovoltaïque',
  [Family.ACCESS_CONTROL]: "Contrôle d'accès",
  [Family.ENERGY_FLUID]: 'Énergie & fluide',
  [Family.FIRE_SAFETY]: 'Sécurité incendie',
  [Family.CHARGING_STATION]: 'Borne de rechargement',
  [Family.OTHER]: 'Autre',
};

export const FamilyNamePlural = {
  [Family.ELEVATOR]: 'Ascenseurs',
  [Family.PARKING_DOOR]: 'Portes de parking',
  [Family.OPTICAL_FIBER]: 'Fibres optique',
  [Family.RELAY_ANTENNA]: 'Antennes relais',
  [Family.IOT]: 'Object connectés',
  [Family.PHOTOVOLTAIC_PANEL]: 'Panneaux photovoltaïques',
  [Family.ACCESS_CONTROL]: "Contrôle d'accès",
  [Family.ENERGY_FLUID]: 'Énergie & fluides',
  [Family.FIRE_SAFETY]: 'Sécurité incendie',
  [Family.CHARGING_STATION]: 'Bornes de rechargement',
  [Family.OTHER]: 'Autre',
};
