/** @format */

import {Component, OnInit, Input, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {clone} from 'lodash-es';
import {v4 as uuidv4} from 'uuid';

import {IChartLabels, IChartData} from '../chart.interface';
import * as Highcharts from 'highcharts';
declare var require: any;
import Boost from 'highcharts/modules/boost';
Boost(Highcharts);
import noData from 'highcharts/modules/no-data-to-display';
noData(Highcharts);
import More from 'highcharts/highcharts-more';
More(Highcharts);
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);
import ExportData from 'highcharts/modules/export-data';
ExportData(Highcharts);

import Drilldown from 'highcharts/modules/drilldown';
import {highchartsOptions} from '../../../constants/highcharts';
import {fadeOut} from '../../../constants/animations';
Drilldown(Highcharts);

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'sesio-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss'],
  animations: [fadeOut],
  encapsulation: ViewEncapsulation.None,
})
export class AreaChartComponent implements OnInit {
  public id = `area-chart-${uuidv4()}`;

  public loading = true;

  @Input('loading')
  public loadingData = true;

  @Input()
  private labels: IChartLabels = {} as IChartLabels;

  @Input()
  public format = '.2f';

  @Input()
  public suffix = '';

  @Input()
  public color = '#4572A7';

  @Input()
  public plotColor = '#666666';

  @Input()
  public min: number;

  @Input()
  public max: number;

  @Input()
  public hideAxisTitle = false;

  @Input('config')
  public set setConfig(config: any) {
    if (config && config.labels) {
      this.labels = config.labels;
    }
    if (config && config.format) {
      this.format = config.format;
    }
    if (config && config.suffix) {
      this.suffix = config.suffix;
    }
    if (config && config.color) {
      this.color = config.color;
    }
    if (config && config.plotColor) {
      this.plotColor = config.plotColor;
    }
    if (config && config.min) {
      this.min = config.min;
    }
    if (config && config.max) {
      this.max = config.max;
    }
    if (config && config.hideAxisTitle) {
      this.hideAxisTitle = config.hideAxisTitle;
    }
  }

  @Input('data')
  public set setData(data: IChartData[]) {
    this.updateData(data);
  }

  private data: IChartData[] = [];

  private chart: Highcharts.Chart;

  private nameLabel: string;

  constructor(private translate: TranslateService) {}

  async ngOnInit(): Promise<void> {
    Highcharts.setOptions(highchartsOptions[this.translate.getDefaultLang()]);
    this.nameLabel = await this.translate.get(this.labels.column).toPromise();
  }

  public async loadChart($event: any): Promise<void> {
    if (!$event.visible || !this.loading) {
      return;
    }
    this.loading = false;
    const series: any = [{name: this.nameLabel, color: this.color, yAxis: 0, data: this.data}];
    const yAxis: any[] = [
      {
        title: {text: this.hideAxisTitle ? null : this.nameLabel, style: {color: this.color}},
        labels: {style: {color: this.color}, format: `{value}${this.suffix || ''}`},
      },
    ];
    if (this.min) {
      yAxis[0].min = this.min;
    }
    if (this.max) {
      yAxis[0].max = this.max;
    }
    this.chart = Highcharts.chart(this.id, {
      chart: {
        type: 'area',
        events: {
          drilldown: e => this.triggerDrilldown(e),
          drillup: () => this.chart.setTitle({text: ''}),
        },
      },
      credits: false,
      title: {text: ''},
      xAxis: {type: 'category', tickmarkPlacement: 'on'},
      yAxis,
      series,
      tooltip: {
        shared: true,
        valueSuffix: this.suffix || '',
        pointFormat: `<span style="color:{series.color}">{series.name}</span>: <b>{point.y:${this.format}}${
          this.suffix || ''
        }</b><br/>`,
      },
      plotOptions: {
        area: {lineColor: this.plotColor, lineWidth: 1, marker: {lineWidth: 1, lineColor: this.plotColor}},
      },
      drilldown: {
        allowPointDrilldown: false,
        activeAxisLabelStyle: {
          color: 'var(--ion-color-primary)',
          textDecoration: 'none',
        },
        drillUpButton: {
          relativeTo: 'spacingBox',
          position: {y: -10, x: -30},
          theme: {
            class: 'drilldown-back-btn',
            fill: 'var(--ion-color-primary)',
            'stroke-width': 0,
            r: 8,
            height: 24,
            style: {
              color: 'white',
            },
            states: {
              hover: {fill: 'var(--ion-color-primary-tint)'},
              select: {fill: 'var(--ion-color-primary-tint)'},
            },
          },
        },
      },
    } as any);
  }

  private async triggerDrilldown(event: any): Promise<void> {
    if (event.seriesOptions) {
      return;
    }
    const option: IChartData = event.point.options;
    if (!option.asyncDrilldown) {
      return;
    }
    this.chart.showLoading();
    (option as any).drilldownData = option.asyncDrilldown(option.drilldown);
    const data = await (option as any).drilldownData;
    (this.chart as any).addSingleSeriesAsDrilldown(event.point, {
      name: `${this.nameLabel} (${option.drilldown})`,
      color: this.color,
      yAxis: 0,
      data,
    } as any);
    (this.chart as any).applyDrilldown();
    this.chart.setTitle({text: option.drilldown});
    this.chart.hideLoading();
  }

  private updateData(data: IChartData[]): void {
    this.data = [];
    if (data) {
      data.forEach(d => {
        const nd = clone(d);
        if (nd.drilldown) {
          nd.name = d.name;
        }
        this.data.push(nd);
      });
    }
    if (this.chart) {
      this.chart.series[0].setData(this.data);
      this.chart.redraw();
    }
    setTimeout(() => (this.loadingData = !data));
  }
}
