/** @format */

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ModelMapper} from 'model-mapper';
import {lastValueFrom, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Esi} from '../classes/esi';
import {LibConfig, LibConfigService} from '../config.service';

export interface IEsiSearch {
  organizationalUnit?: {
    id: string;
    name: string;
    pathNames: string[];
  };
  perimeter?: boolean;
  natures?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class EsiService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}
  public get(id: string): Promise<Esi> {
    return lastValueFrom(
      this.http
        .get<any>(`${this.config.environment.apiUrl}/esis/${id}`)
        .pipe(map(data => new ModelMapper(Esi).map(data)))
    );
  }

  public search({
    code,
    natures,
    organizationalUnitId,
  }: {code?: string; natures?: string[]; organizationalUnitId?: string} = {}): Observable<Esi[]> {
    const params: any = {};
    if (code) {
      params.code = code;
    }
    if (natures?.length > 0) {
      {
        params.natures = natures;
      }
    }
    if (organizationalUnitId) {
      params.organizationalUnitId = organizationalUnitId;
    }
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/esis`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(Esi).map(d))));
  }

  public getMainIndicators(headerSearch: IEsiSearch): Observable<{nature: string; count: number}[]> {
    return this.http.post<any[]>(
      `${this.config.environment.apiUrl}/esis/get-main-indicators`,
      this.buildEsiSearchFilter(headerSearch)
    );
  }

  public getMapInfo(
    headerSearch: IEsiSearch
  ): Observable<{coordinates: [number, number]; total: number; natures: {nature: string; count: number}[]}[]> {
    return this.http.post<any[]>(
      `${this.config.environment.apiUrl}/esis/get-map-info`,
      this.buildEsiSearchFilter(headerSearch)
    );
  }

  protected buildEsiSearchFilter(search: IEsiSearch): any {
    const filter: any = {};
    if (search?.perimeter) {
      filter.perimeter = true;
    } else if (search?.organizationalUnit) {
      filter.organizationalUnitId = search.organizationalUnit.id;
    }
    if (search?.natures?.length > 0) filter.natures = search.natures;
    return filter;
  }
}
