/** @format */

import {Injectable} from '@angular/core';
import {Moment} from 'moment';
import {Observable} from 'rxjs';
import {
  EquipmentKind,
  EquipmentStatusHistoryField,
  EquipmentStatusHistoryService as SesioEquipmentStatusHistoryService,
} from 'sesio-lib';
import {IHeaderSearch} from '../_classes/header-search';

// TODO add filter on qualification
export interface IFilter extends IHeaderSearch {
  equipmentId?: string;
  families?: string[];
  contractorIds?: string[];
  start?: Moment;
  end?: Moment;
  field?: EquipmentStatusHistoryField;
  statuses: string[];
  unit?: string;
  binSize?: number;
  equipmentKind?: EquipmentKind;
  signatureSettingName?: string;
  isTertiary?: boolean;
  supervised?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentStatusHistoryService extends SesioEquipmentStatusHistoryService {
  public count(search: IFilter): Observable<{count: number}> {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/equipment-status-history/count`,
      this.buildSearchFilter(search)
    );
  }
}
