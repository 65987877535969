/** @format */

import {propertyMap} from 'model-mapper';

export class EsiMetrics {
  @propertyMap()
  public energy: number;

  @propertyMap()
  public climat: number;

  @propertyMap()
  public greenEnergy: number;

  @propertyMap()
  public ecs: number;

  @propertyMap()
  public heating: number;
}

export class ValueAndTrend {
  @propertyMap()
  public value: number;

  @propertyMap()
  public trend: number;
}
export class ValueInfo {
  @propertyMap()
  sum: number;

  @propertyMap()
  value: number;

  @propertyMap()
  count: number;
}
