/** @format */

export enum InspectionStatus {
  INFEASIBLE = '10-infeasible',
  OUT_OF_TIME = '20-out-of-time',
  LATE = '30-late',
  NEW = '40-new',
  PLANNED = '50-planned',
  WIP = '60-wip',
  TO_VALIDATE = '65-to-validate', // En attente de l'ajout de document ou vérification
  DONE = '70-done',
  CANCELED = '80-canceled',
  ARCHIVED = '90-archived',
}

export const InspectionStatusName = {
  [InspectionStatus.INFEASIBLE]: 'Infaisable',
  [InspectionStatus.OUT_OF_TIME]: 'Hors délais',
  [InspectionStatus.LATE]: 'En retard',
  [InspectionStatus.NEW]: 'Nouvelle',
  [InspectionStatus.PLANNED]: 'Planifiée',
  [InspectionStatus.WIP]: 'En cours',
  [InspectionStatus.TO_VALIDATE]: 'À valider',
  [InspectionStatus.DONE]: 'Terminée',
  [InspectionStatus.CANCELED]: 'Annulée',
  [InspectionStatus.ARCHIVED]: 'Archivée',
};

export enum InspectionState {
  DANGER = 'danger',
  ANOMALY = 'anomaly',
  OK = 'ok',
  LATE = 'late',
  KO = 'ko',
}

export enum InspectionEquipmentStatus {
  OK = 'ok',
  KO = 'ko',
  MISSING = 'missing',
}
