<div *ngIf="!shrink" class="block" fxLayout="row" fxLayoutAlign="space-between center">

  <div class="left-block" fxLayout="column" fxLayoutAlign="center center">

    <ng-container [ngSwitch]="family">
      <ng-container *ngSwitchCase="'access-control'"></ng-container>
      <ng-container *ngSwitchDefault>
        <div class="left-block-header" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="18px">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <span color="dark">Équipements :</span>
            <span color="primary" [style.font-weight]="500" [countUp]="data?.equipmentCount"
              [options]="{ duration: 1, separator: ' ' }" [reanimateOnClick]="false"></span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <span color="dark">État :</span>
            <app-star-rate [value]="0"></app-star-rate>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div class="left-block-content" fxLayout="row wrap" fxLayoutAlign="space-evenly center" [style.font-size.px]="16"
      [style.min-height.px]="150">
      <ng-container [ngSwitch]="family">
        <ng-container *ngSwitchCase="'fire-safety'">
          <ng-container *ngTemplateOutlet="qualityIndexTmpl"></ng-container>
          <ng-container *ngTemplateOutlet="controlRateTmpl"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'access-control'">
          <sesio-chart #accessControlChart [options]="accessControlChartOptions"
            [style]="{ height: '100%', width: '100%' }"></sesio-chart>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngTemplateOutlet="failureTmpl"></ng-container>
          <ng-container *ngTemplateOutlet="availabilityTmpl"></ng-container>
        </ng-container>
      </ng-container>
    </div>

  </div>

  <div class="right-block" fxLayout="column" fxLayoutAlign="center center">
    <ng-container [ngSwitch]="family">
      <ng-container *ngSwitchCase="'access-control'">
        <ng-container *ngTemplateOutlet="accessControlStatusTmpl"></ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="statusTmpl"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<div *ngIf="shrink" fxLayout="column" fxLayoutAlign="space-between start" [style.padding.px]="4" [style.width.%]="100"
  [style.height.px]="150">
  <div fxLayoutAlign="space-between center" fxLayoutGap="4px" [style.width.%]="100">
    <ng-container *ngTemplateOutlet="statusTmpl"></ng-container>
    <app-star-rate [value]="state" [style.width.px]="80"></app-star-rate>
  </div>
  <div fxLayoutAlign="space-between center" [style.width.%]="100" [style.font-size.px]="12" [style.min-height.px]="120"
    [style.padding]="'0 8px'">
    <ng-container [ngSwitch]="family">
      <ng-container *ngSwitchCase="'fire-safety'">
        <ng-container *ngTemplateOutlet="qualityIndexTmpl"></ng-container>
        <ng-container *ngTemplateOutlet="controlRateTmpl"></ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="failureTmpl"></ng-container>
        <ng-container *ngTemplateOutlet="availabilityTmpl"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #statusTmpl>
  <sesio-loader *ngIf="!data.statuses?.length"></sesio-loader>
  <div *ngFor="let s of data.statuses" class="status {{family}}" fxLayoutAlign="space-between center" fxLayoutGap="4px"
    [matTooltip]="s.name" (click)="openStatus(s.value)">
    <mat-icon [style.color]="s.color | safe:'style'">{{s.icon.name}}</mat-icon>
    <span [style.color]="s.color | safe:'style'" class="value" [countUp]="s.count || 0"
      [options]="{ duration: 1, separator: ' ' }" [reanimateOnClick]="false">
    </span>
  </div>
</ng-template>

<ng-template #accessControlStatusTmpl>
  <sesio-loader *ngIf="data?.accessControl?.loading"></sesio-loader>
  <div *ngFor="let s of accessControlStatus" class="status" fxLayoutAlign="space-between center" fxLayoutGap="5px"
    [style.color]="s.color">
    <span translate>{{s.name}}</span>
    <span [style.color]="s.color | safe:'style'" class="value"
      [countUp]="(data?.accessControl?.countByStatus && data?.accessControl?.countByStatus[s.status]) || 0"
      [options]="{ duration: 1, separator: ' ' }" [reanimateOnClick]="false">
    </span>
  </div>
</ng-template>

<ng-template #failureTmpl>
  <app-stats-block *ngIf="!data.failureRate.loading; else loaderTmpl" title="Taux de panne" evolutionUnit=""
    [reverse]="true" [value]="data.failureRate.value?.value" unit="/ an">
  </app-stats-block>
  <div *ngIf="!data.failureRate.loading && data.failureRate.error" class="stats-block" fxLayout="column"
    fxLayoutAlign="center center">
    <span color="warn">{{data.failureRate.error}}</span>
  </div>
</ng-template>

<ng-template #availabilityTmpl>
  <app-stats-block *ngIf="!data.availabilityRate.loading; else loaderTmpl" title="Taux de disponibilité"
    [value]="data.availabilityRate.value?.value" unit="%">
  </app-stats-block>
  <div *ngIf="!data.availabilityRate.loading && data.availabilityRate.error" class="stats-block" fxLayout="column"
    fxLayoutAlign="center center">
    <span color="warn">{{data.availabilityRate.error}}</span>
  </div>
</ng-template>

<ng-template #qualityIndexTmpl>
  <app-stats-block *ngIf="!data.qualityIndex.loading; else loaderTmpl" title="Indice Qualité"
    [color]="data.qualityIndex.value?.value | qualityColor" [value]="data.qualityIndex.value?.value" unit="/ 5"
    [evolution]="data.qualityIndex.value?.trend" [reverse]="false">
  </app-stats-block>
  <div *ngIf="!data.qualityIndex.loading && data.qualityIndex.error" class="stats-block" fxLayout="column"
    fxLayoutAlign="center center">
    <span color="warn">{{data.qualityIndex.error}}</span>
  </div>
</ng-template>

<ng-template #controlRateTmpl>
  <app-stats-block *ngIf="!data.controlRate.loading; else loaderTmpl" title="Taux de contrôle"
    [color]="data.controlRate.value?.value | controlRateColor" [value]="data.controlRate.value?.value" unit="%"
    [evolution]="data.controlRate.value?.trend">
  </app-stats-block>
  <div *ngIf="!data.controlRate.loading && data.controlRate.error" class="stats-block" fxLayout="column"
    fxLayoutAlign="center center">
    <span color="warn">{{data.controlRate.error}}</span>
  </div>
</ng-template>

<ng-template #loaderTmpl>
  <div @fadeOut class="stats-block" fxLayout="column" fxLayoutAlign="center center">
    <sesio-loader></sesio-loader>
  </div>
</ng-template>