/** @format */

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ModelMapper} from 'model-mapper';
import {lastValueFrom} from 'rxjs';
import {map} from 'rxjs/operators';
import {RealEstateStructureAlert} from '../classes/real-estate-structure-alert';
import {LibConfig, LibConfigService} from '../config.service';
import {ISearchResult} from '../classes/search-result';

export interface IRealEstateStructureAlertFilter {
  organizationalUnit?: {
    id: string;
    name: string;
    pathNames: string[];
  };
  perimeter?: boolean;
  realEstateStructureId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class RealEstateStructureAlertService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public async list(filter: IRealEstateStructureAlertFilter = {}): Promise<RealEstateStructureAlert[]> {
    return lastValueFrom(
      this.http
        .get<any>(`${this.config.environment.apiUrl}/real-estate-structure-alert`, {
          params: this.buildSearchFilter(filter),
        })
        .pipe(map(data => data.map(d => new ModelMapper(RealEstateStructureAlert).map(d))))
    );
  }

  public find(
    options: {fields: string; limit?: number; start?: number},
    filter?: IRealEstateStructureAlertFilter
  ): Promise<ISearchResult> {
    const query: any = {draw: Date.now().toString(), start: 0, columns: [], order: []};
    options.fields.split(' ').forEach((f, i) => {
      if (f.startsWith('-')) {
        query.order.push({column: i, dir: 'desc'});
        f = f.slice(1);
      } else if (f.startsWith('+')) {
        query.order.push({column: i, dir: 'asc'});
        f = f.slice(1);
      }
      query.columns.push({data: f, name: f, searchable: true});
    });
    if (options.limit) query.length = options.limit;
    if (options.start) query.start = options.start;
    return lastValueFrom(
      this.http.post<any>(`${this.config.environment.apiUrl}/real-estate-structure-alert/datatable`, {query, filter})
    );
  }

  public count(filter?: IRealEstateStructureAlertFilter): Promise<number> {
    return lastValueFrom(
      this.http.post<any>(`${this.config.environment.apiUrl}/real-estate-structure-alert/count`, filter)
    );
  }

  protected buildSearchFilter(search: IRealEstateStructureAlertFilter): any {
    const filter: any = {};
    if (search?.perimeter) {
      filter.perimeter = true;
    } else if (search?.organizationalUnit) {
      filter.organizationalUnitId = search.organizationalUnit.id;
    }
    if (search?.realEstateStructureId) filter.realEstateStructureId = search.realEstateStructureId;
    return filter;
  }
}
