/** @format */

import {Pipe, PipeTransform} from '@angular/core';
import humanizeDuration from 'humanize-duration';
import {isNil, merge} from 'lodash-es';
import {Moment} from 'moment';

@Pipe({name: 'since'})
export class SincePipe implements PipeTransform {
  transform(data: Date | Moment | number | string, options?: humanizeDuration.Options): string | null {
    if (isNil(data)) return null;
    const timestamp = this.getTimestamp(data);
    if (isNil(timestamp)) return null;
    return humanizeDuration(Date.now() - timestamp, merge({language: 'fr', maxDecimalPoints: 0, round: true}, options));
  }

  getTimestamp(data: Date | Moment | number | string): number | null {
    switch (typeof data) {
      case 'string':
        return new Date(data).valueOf();
      case 'number':
        return data;
      default:
        if (typeof data.valueOf === 'function') return data.valueOf();
        return null;
    }
  }
}
