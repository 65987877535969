/** @format */

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ModelMapper} from 'model-mapper';
import {lastValueFrom, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {File} from '../classes/file';
import {DirectOrder, OrderStatus} from '../classes/order';
import {ISearchResult} from '../classes/search-result';
import {LibConfig, LibConfigService} from '../config.service';

export interface IDirectOrderFilter {
  organizationalUnit?: {
    id: string;
    name: string;
    pathNames: string[];
  };
  perimeter?: boolean;
  statuses?: OrderStatus[];
}

@Injectable({
  providedIn: 'root',
})
export class DirectOrderService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public find(filter?: {statuses?: OrderStatus[]; organizationalUnitId?: string}): Observable<DirectOrder[]> {
    const params: any = {};
    if (filter.statuses?.length) params.statuses = filter.statuses;
    if (filter.organizationalUnitId) params.organizationalUnitId = filter.organizationalUnitId;
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/direct-orders`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(DirectOrder).map(d))));
  }

  public list(
    options: {fields: string; limit?: number; start?: number},
    filter?: IDirectOrderFilter
  ): Promise<ISearchResult> {
    const query: any = {draw: Date.now().toString(), start: 0, columns: [], order: []};
    options.fields.split(' ').forEach((f, i) => {
      if (f.startsWith('-')) {
        query.order.push({column: i, dir: 'desc'});
        f = f.slice(1);
      } else if (f.startsWith('+')) {
        query.order.push({column: i, dir: 'asc'});
        f = f.slice(1);
      }
      query.columns.push({data: f, name: f, searchable: true});
    });
    if (options.limit) query.length = options.limit;
    if (options.start) query.start = options.start;
    return lastValueFrom(
      this.http.post<any>(`${this.config.environment.apiUrl}/direct-orders/datatable`, {
        query,
        filter: this.buildSearchFilter(filter),
      })
    );
  }

  public get(id: string): Promise<DirectOrder> {
    return lastValueFrom(
      this.http
        .get<any[]>(`${this.config.environment.apiUrl}/direct-orders/${id}`)
        .pipe(map(data => new ModelMapper(DirectOrder).map(data)))
    );
  }

  public setStatus(
    id: string,
    data: {status: OrderStatus; interventionDate?: Date; interventionReport?: File; date?: Date}
  ): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/direct-orders/${id}/set-status`, data);
  }

  public count(search: IDirectOrderFilter): Promise<number> {
    return lastValueFrom(
      this.http.post<any>(`${this.config.environment.apiUrl}/direct-orders/count`, this.buildSearchFilter(search))
    );
  }

  public cancel(id: string): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/direct-orders/${id}/cancel`, {});
  }

  protected buildSearchFilter(search: IDirectOrderFilter): any {
    const filter: any = {};
    if (search?.perimeter) {
      filter.perimeter = true;
    } else if (search?.organizationalUnit) {
      filter.organizationalUnitId = search.organizationalUnit.id;
    }
    if (search?.statuses?.length) filter.statuses = search.statuses;
    return filter;
  }
}
