/** @format */

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {isNil} from 'lodash-es';
import {ModelMapper} from 'model-mapper';
import 'moment-timezone';
import {lastValueFrom, map} from 'rxjs';
import {EquipmentKind} from '../classes/equipment-kind';
import {Inspection} from '../classes/inspection';
import {LibConfig, LibConfigService} from '../config.service';

export interface IEquipmentFilter {
  organizationalUnit?: {
    id: string;
    name: string;
    pathNames: string[];
  };
  organizationalUnitId?: string;
  perimeter?: boolean;
  statuses?: string[];
  families?: string[];
  categories?: string[];
  contractorIds?: string[];
  equipmentKindIds?: string[];
  supervision?: boolean;
  hypervision?: boolean;
  realEstateStructureId?: string;
  equipmentId?: string;
  equipmentKindId?: string;
  equipmentKind?: EquipmentKind;
  signatureSettingName?: string;
  isTertiary?: boolean;
  supervised?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public async getSate(search: IEquipmentFilter): Promise<number> {
    return lastValueFrom(
      this.http.post<number>(`${this.config.environment.apiUrl}/equipments/get-state`, this.buildSearchFilter(search))
    );
  }

  public count(search: IEquipmentFilter): Promise<{family: string; category: string; kindId: string; count: number}[]> {
    const filter = this.buildSearchFilter(search);
    return lastValueFrom(this.http.post<any>(`${this.config.environment.apiUrl}/equipments/get-count`, filter));
  }

  public getInspections(id: string): Promise<Inspection[]> {
    return lastValueFrom(
      this.http
        .get<Inspection[]>(`${this.config.environment.apiUrl}/equipments/${id}/inspections`)
        .pipe(map(data => data.map(d => new ModelMapper(Inspection).map(d))))
    );
  }

  protected buildSearchFilter(search: IEquipmentFilter): any {
    const filter: any = {};
    if (search?.perimeter) {
      filter.perimeter = true;
    } else if (search?.organizationalUnit) {
      filter.organizationalUnitId = search.organizationalUnit.id;
    }
    if (search?.organizationalUnitId) filter.organizationalUnitId = search.organizationalUnitId;
    if (search?.statuses) filter.statuses = search.statuses;
    if (search?.categories) filter.categories = search.categories;
    if (search?.families) filter.families = search.families;
    if (search?.contractorIds?.length) filter.contractorIds = search.contractorIds;
    if (search?.equipmentKindIds?.length) filter.equipmentKindIds = search.equipmentKindIds;
    if (!isNil(search?.supervision)) filter.supervision = search.supervision;
    if (!isNil(search?.hypervision)) filter.hypervision = search.hypervision;
    if (search?.realEstateStructureId) filter.realEstateStructureId = search.realEstateStructureId;
    if (search?.equipmentId) filter.equipmentId = search.equipmentId;
    if (search?.equipmentKindId) filter.equipmentKindId = search.equipmentKindId;
    if (search?.equipmentKind) filter.equipmentKindId = search.equipmentKind.id;
    if (search?.signatureSettingName) filter.signatureSettingName = search.signatureSettingName;
    if (!isNil(search?.isTertiary)) filter.isTertiary = search.isTertiary;
    if (!isNil(search?.supervised)) filter.supervised = search.supervised;
    return filter;
  }
}
