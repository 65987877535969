/** @format */

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import 'moment-timezone';
import {lastValueFrom} from 'rxjs';
import {InterventionStatus} from '../classes/intervention';
import {ISearchResult} from '../classes/search-result';
import {LibConfig, LibConfigService} from '../config.service';

export interface IInterventionFilter {
  organizationalUnit?: {
    id: string;
    name: string;
    pathNames: string[];
  };
  perimeter?: boolean;
  statuses?: InterventionStatus[];
  realEstateStructureIds?: string[];
  inspectionIds?: string[];
  equipmentId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class InterventionService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public find(
    options: {fields: string; limit?: number; start?: number},
    filter?: IInterventionFilter
  ): Promise<ISearchResult> {
    const query: any = {draw: Date.now().toString(), start: 0, columns: [], order: []};
    options.fields.split(' ').forEach((f, i) => {
      if (f.startsWith('-')) {
        query.order.push({column: i, dir: 'desc'});
        f = f.slice(1);
      } else if (f.startsWith('+')) {
        query.order.push({column: i, dir: 'asc'});
        f = f.slice(1);
      }
      query.columns.push({data: f, name: f, searchable: true});
    });
    if (options.limit) query.length = options.limit;
    if (options.start) query.start = options.start;
    return lastValueFrom(
      this.http.post<any>(`${this.config.environment.apiUrl}/interventions/datatable`, {query, filter})
    );
  }

  protected buildSearchFilter(search: IInterventionFilter): any {
    const filter: any = {};
    if (search?.perimeter) {
      filter.perimeter = true;
    } else if (search?.organizationalUnit) {
      filter.organizationalUnitId = search.organizationalUnit.id;
    }
    if (search?.statuses?.length) filter.statuses = search.statuses;
    if (search?.realEstateStructureIds?.length) filter.realEstateStructureIds = search.realEstateStructureIds;
    if (search?.inspectionIds?.length) filter.inspectionIds = search.inspectionIds;
    if (search?.equipmentId) filter.equipmentId = search.equipmentId;
    return filter;
  }
}
