/** @format */

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ModelMapper} from 'model-mapper';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Family} from '../classes/family';
import {
  UnitPriceSchedule,
  UnitPriceScheduleAccessControlCategory,
  UnitPriceScheduleFireSafetyCategory,
} from '../classes/unit-price-schedule';
import {LibConfig, LibConfigService} from '../config.service';

@Injectable({
  providedIn: 'root',
})
export class UnitPriceScheduleService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public list({
    families,
    categories,
    contractorId,
  }: {
    families?: Family[];
    categories?: (UnitPriceScheduleAccessControlCategory | UnitPriceScheduleFireSafetyCategory)[];
    contractorId?: string;
  } = {}): Observable<UnitPriceSchedule[]> {
    const params: any = {};
    if (families) params.families = families;
    if (categories) params.categories = categories;
    if (contractorId) params.contractorId = contractorId;
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/unit-price-schedules`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(UnitPriceSchedule).map(d))));
  }

  public countByFamily(): Observable<{family: Family; count: number}[]> {
    return this.http.get<any[]>(`${this.config.environment.apiUrl}/unit-price-schedules/count-by-family`);
  }

  public get(id: string): Observable<UnitPriceSchedule> {
    return this.http
      .get<any>(`${this.config.environment.apiUrl}/unit-price-schedules/${id}`)
      .pipe(map(data => new ModelMapper(UnitPriceSchedule).map(data)));
  }

  public delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.config.environment.apiUrl}/unit-price-schedules/${id}`);
  }

  public update(id: string, data: any): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/unit-price-schedules/${id}`, data);
  }

  public create(data: any): Observable<UnitPriceSchedule> {
    return this.http.post<any>(`${this.config.environment.apiUrl}/unit-price-schedules`, data);
  }
}
