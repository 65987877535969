/** @format */

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {concat, get, isEqual, isNil, pull, trim, uniqBy} from 'lodash-es';
import {IntersectionObserverEvent} from 'ngx-intersection-observer/lib/intersection-observer-event.model';
import {NGXLogger} from 'ngx-logger';
import {debounceTime, exhaustMap, filter, Observable, scan, startWith, Subject, switchMap, tap} from 'rxjs';
import {EmbeddedEquipmentKind, EquipmentKind, fade, Family} from 'sesio-lib';
import {SubSink} from 'subsink/dist/subsink';
import {EquipmentKindService} from '../../_services/equipment-kind.service';

type FieldValueType = EmbeddedEquipmentKind | EmbeddedEquipmentKind[] | null;

@Component({
  selector: 'app-equipment-kind-select',
  templateUrl: './equipment-kind-select.component.html',
  styleUrls: ['./equipment-kind-select.component.scss'],
  animations: [fade],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EquipmentKindSelectComponent),
    },
  ],
})
export class EquipmentKindSelectComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  ready = false;

  @Input('limit')
  set setLimitInput(limit: number) {
    this.setLimit(limit);
  }
  limit: number = 10;

  @Input()
  family: Family;

  @Input()
  appearance: MatFormFieldAppearance = 'outline';

  @Input('required')
  set setRequired(isRequired: boolean) {
    this.isRequired = isRequired === false ? false : true;
    if (this.control) this.setValidators();
  }
  isRequired = false;

  @Input('value')
  set setValue(value: EquipmentKind) {
    if (!isEqual(this.value, value)) {
      this.value = value;
      this.selectControl.setValue(value);
    }
  }
  value: FieldValueType;

  @Input('disabled')
  set setDisabled(isDisabled: any) {
    this.setDisabledState(isDisabled !== false);
  }
  isDisabled: boolean;

  @Input('label')
  label: string;

  @Input('multiple')
  set setMultiple(multiple: boolean | string) {
    this.multiple = typeof multiple === 'boolean' ? multiple : !isNil(multiple);
  }
  multiple: boolean;

  selectControl = new FormControl();
  control: FormControl<FieldValueType>;

  searching = false;
  options$: Observable<EquipmentKind[]>;
  searchMatched: number;

  @ViewChild('autocompleteInput') input: ElementRef<HTMLInputElement>;

  private subsink = new SubSink();

  public onChange = () => {};

  public onTouched = () => {};

  constructor(
    private logger: NGXLogger,
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef,
    private equipmentKindService: EquipmentKindService
  ) {}

  writeValue(value: FieldValueType): void {
    if (typeof value !== 'string' && !isEqual(this.selectControl.value, value)) {
      this.selectControl.setValue(value);
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (this.isDisabled) this.selectControl.disable();
    else this.selectControl.enable();
  }

  async ngAfterViewInit(): Promise<void> {
    const ngControl: NgControl | null = this.injector.get(NgControl, null);
    if (ngControl) {
      this.control = ngControl.control as UntypedFormControl;
      this.isRequired = this.control.hasValidator(Validators.required);
      this.value = this.control.value;
      this.isDisabled = this.control.disabled;
    } else {
      this.control = new UntypedFormControl({
        value: this.value,
        disabled: this.isDisabled,
      });
    }
    this.setValidators();
    if (!this.multiple) {
      this.selectControl.setValue(this.control.value);
      this.subsink.add(
        this.selectControl.valueChanges.subscribe(async value => {
          if (typeof value !== 'string') this.control.setValue(value);
        })
      );
    }
    this.buildOptions();
    this.ready = true;
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  displayOption(option: EquipmentKind): string {
    return option?.name;
  }

  selectedAutocompleteValue(event: MatAutocompleteSelectedEvent): void {
    this.control.setValue(uniqBy(concat(this.control?.value || [], [event.option.value]), 'id'));
    this.input.nativeElement.value = '';
    this.selectControl.setValue(null);
  }

  removeAutocompleteValue(data: any): void {
    const value = this.control?.value as EmbeddedEquipmentKind[];
    pull(value, data);
    this.control?.setValue(value);
  }

  setLimit(limit: number) {
    this.limit = limit;
    if (this.selectControl) {
      this.selectControl.setValue(typeof this.selectControl.value === 'string' ? this.selectControl.value : '');
    }
  }

  loadMore($event: IntersectionObserverEvent) {
    if (!$event.intersect) return;
    this.nextPage$.next();
  }

  onfocus() {
    if (!this.selectControl.value || typeof this.selectControl.value === 'string') {
      this.selectControl.setValue(get(this.selectControl.value, 'name', ''));
    }
  }

  private nextPage$ = new Subject<void>();
  private buildOptions() {
    const filter$: Observable<string> = this.selectControl.valueChanges.pipe(
      filter(value => typeof value === 'string'),
      debounceTime(300),
      tap(() => (this.searching = true))
    );
    this.options$ = filter$.pipe(
      switchMap((search: string) => {
        let skip = 0;
        return this.nextPage$.pipe(
          startWith(skip),
          exhaustMap(() =>
            this.equipmentKindService.search(this.limit, skip, {
              search: trim(search),
              families: this.family ? [this.family] : undefined,
            })
          ),
          tap(() => (skip += this.limit)),
          tap(list => (this.searchMatched = list.matchCount)),
          scan((data, list) => data.concat(list.data), [] as EquipmentKind[])
        );
      }),
      tap(() => (this.searching = false))
    );
  }

  private setValidators() {
    if (this.isRequired && !this.selectControl.hasValidator(Validators.required)) {
      this.selectControl.addValidators(Validators.required);
    }
    if (!this.selectControl.hasValidator(this.validateEntity)) this.selectControl.addValidators(this.validateEntity);
    this.selectControl.updateValueAndValidity();
  }

  private validateEntity = (control: AbstractControl): {[key: string]: any} | null => {
    const forbidden = control.value && !control.value?._id;
    return forbidden ? {invalidEntity: {value: control.value}} : null;
  };
}
