/** @format */

import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import Highcharts from 'highcharts';
import * as Highstock from 'highcharts/highstock';
import {
  divide,
  each,
  find,
  get,
  includes,
  isEqual,
  filter as lfilter,
  map,
  merge,
  multiply,
  omit,
  orderBy,
  pick,
  reduce,
} from 'lodash-es';
import {Moment} from 'moment';
import {Subscription, lastValueFrom} from 'rxjs';
import {
  AggregationValue,
  ChartComponent,
  EquipmentKind,
  EquipmentStatusHistoryField,
  Family,
  Metric,
  SensorDataService,
  SignatureFlowState,
  SignatureStatus,
  SignatureStatusColor,
  consolidateDayData,
  highchartsOptions,
} from 'sesio-lib';
import {SubSink} from 'subsink';
import {IHeaderSearch, convertHeaderSelection} from '../../../_classes/header-search';
import {EquipmentAvailabilityService} from '../../../_services/equipment-availability.service';
import {EquipmentFailureService} from '../../../_services/equipment-failure.service';
import {EquipmentReportService} from '../../../_services/equipment-report.service';
import {EquipmentStatusHistoryService} from '../../../_services/equipment-status-history.service';
import {EquipmentService} from '../../../_services/equipment.service';
import {IHeaderSelection, SessionService} from '../../../_services/session.service';
import {EquipmentAvailabilityFailureChartComponent} from '../../equipment/equipment-availability-failure-chart/equipment-availability-failure-chart.component';

const COLORS = [
  {color: '#80d6ff', contrast: '#000000'},
  {color: '#8e99f3'},
  {color: '#42a5f5'},
  {color: '#58c7cc'},
  {color: '#dddddd', contrast: '#000000'},
];

interface IFilter extends IHeaderSearch {
  supervised: true;
  families: string[];
  contractorIds?: string[];
  equipmentKind?: EquipmentKind;
}

@Component({
  selector: 'app-statistics-elevator-space',
  templateUrl: './elevator-space.component.html',
  styleUrls: ['./elevator-space.component.scss'],
})
export class ElevatorSpaceComponent implements OnInit, OnDestroy {
  DAY_ORDER = [2, 3, 4, 5, 6, 7, 1];
  DAYS = highchartsOptions[this.translate.getDefaultLang()].lang.weekdays;
  Family = Family;
  filter: FormGroup;

  public contractors: {name: string; value: string}[];
  public contractorColors = {};
  public contractorEquipmentCount: AggregationValue[];
  extremes: {start: Moment; end: Moment};

  public stackChartOptions: Highstock.Options;
  @ViewChild('contractorPerfChart', {read: ChartComponent, static: true})
  private contractorPerfChartRef: ChartComponent;
  public pieChartOptions: Highstock.Options;
  @ViewChild('elevatorByContractorChart', {
    read: ChartComponent,
    static: true,
  })
  private elevatorByContractorChartRef: ChartComponent;
  @ViewChild('callByContractorChart', {read: ChartComponent, static: true})
  private callByContractorChartRef: ChartComponent;
  @ViewChild('failureByContractorChart', {read: ChartComponent, static: true})
  private failureByContractorChartRef: ChartComponent;

  public trafficChartOptions: Highcharts.Options;
  @ViewChild('trafficChart', {read: ChartComponent, static: true})
  private trafficChartRef: ChartComponent;

  public failureChartOptions: Highcharts.Options;
  @ViewChild('failureChart', {read: ChartComponent, static: true})
  private failureChartRef: ChartComponent;

  public failureDayChartOptions: Highcharts.Options;
  @ViewChild('failureDayChart', {read: ChartComponent, static: true})
  private failureDayChartRef: ChartComponent;

  public failureHistoryChartOptions: Highcharts.Options;
  @ViewChild('failureHistoryChart', {read: ChartComponent, static: true})
  private failureHistoryChartRef: ChartComponent;

  public availabilityHistoryChartOptions: Highcharts.Options;
  @ViewChild('availabilityHistoryChart', {read: ChartComponent, static: true})
  private availabilityHistoryChartRef: ChartComponent;

  @ViewChild(EquipmentAvailabilityFailureChartComponent)
  availabilityFailureChart: EquipmentAvailabilityFailureChartComponent;

  private subsink = new SubSink();
  private filterSubscription: Subscription;

  constructor(
    private sessionService: SessionService,
    private changeDetectorRef: ChangeDetectorRef,
    private equipmentService: EquipmentService,
    private equipmentFailureService: EquipmentFailureService,
    private equipmentAvailabilityService: EquipmentAvailabilityService,
    private equipmentReportService: EquipmentReportService,
    private formBuilder: FormBuilder,
    private sensorDataService: SensorDataService,
    private equipmentStatusHistoryService: EquipmentStatusHistoryService,
    private translate: TranslateService
  ) {
    this.pieChartOptions = this.buildPieChartOptions();
    this.stackChartOptions = this.buildStackChartOptions();
    this.trafficChartOptions = this.buildTemperatureChartOptions('traffic');
    this.failureChartOptions = this.buildTemperatureChartOptions('failure');
    this.failureDayChartOptions = this.buildTreemapChartOptions();
    this.failureHistoryChartOptions = this.buildFailureHistoryChartOptions();
    this.availabilityHistoryChartOptions = this.buildAvailabilityHistoryChartOptions();
  }

  ngOnInit() {
    this.subsink.add(
      this.sessionService.$headerSelection.subscribe({
        next: selection => this.loadFilter(selection),
      })
    );
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
    if (this.filterSubscription) this.filterSubscription.unsubscribe();
  }

  private async loadFilter(selection: IHeaderSelection) {
    const filter: any = merge(convertHeaderSelection(selection), {
      families: [Family.ELEVATOR],
      supervised: true,
    });
    if (isEqual(this.filter, filter)) return;
    this.filter = this.formBuilder.group({
      organizationalUnit: [filter.organizationalUnit],
      perimeter: [filter.perimeter],
      search: [filter.search],
      families: [filter.families],
      contractorIds: [],
      equipmentKind: [],
      supervised: [filter.supervised],
    });
    await this.loadContractors(this.filter.value);
    if (this.filterSubscription) this.filterSubscription.unsubscribe();
    let previousValue = this.filter.value;
    this.filterSubscription = this.filter.valueChanges.subscribe(async value => {
      if (
        !isEqual(
          pick(previousValue, 'organizationalUnit', 'perimeter', 'search'),
          pick(value, 'organizationalUnit', 'perimeter', 'search')
        )
      ) {
        await this.loadContractors(value);
      }
      this.loadData();
      previousValue = value;
    });
    this.changeDetectorRef.detectChanges();
  }

  private async loadContractors(filter: IFilter) {
    return lastValueFrom(this.equipmentService.getContractors(omit(filter, 'contractorIds')))
      .then(contractors => (each(contractors, (c, i) => (this.contractorColors[c.name] = COLORS[i])), contractors))
      .then(contractors => {
        if (!isEqual(this.contractors, orderBy(contractors, ['name']))) {
          this.contractors = orderBy(
            lfilter(contractors, contractor => !!contractor && !!contractor.name),
            ['name']
          );
          this.filter.get('contractorIds').setValue(
            this.contractors.map(c => c.value),
            {emitEvent: false}
          );
        }
      });
  }

  setExtremes(extremes: {start: Moment; end: Moment}) {
    this.extremes = extremes;
    this.loadData();
  }

  private loadDataTm;
  private loadDataEcho;
  private async loadData() {
    if (!this.extremes || !this.filter) return;
    if (this.loadDataTm) clearTimeout(this.loadDataTm);
    await new Promise<void>(resolve => (this.loadDataTm = setTimeout(() => resolve(), 300)));
    this.loadDataEcho = Date.now();
    const echo = this.loadDataEcho;
    const filter = merge({}, this.filter.value, this.extremes);
    this.loadNoflowCount(echo, filter);
    this.loadNewFailureRate(echo, filter);
    this.loadFailureRate(echo, filter);
    this.loadDisponibilityRate(echo, filter);
    this.loadCallByContractorChartData(echo, filter);
    this.loadTrafficChartData(echo, filter);
    this.loadFailureChartData(echo, filter);
    this.loadFailureDayChartData(echo, filter);
    Promise.all([
      this.loadFailureByContractorChartData(echo, filter),
      this.loadElevatorByContractorChartData(echo, filter),
    ]).then(data => {
      this.loadFailureAndAvaillabilityData(echo, filter);
      this.loadContractorPerfChartData(echo, filter, data);
    });
  }

  private async loadFailureAndAvaillabilityData(echo: number, filter: any) {
    this.failureHistoryChartRef.setLoading(true);
    this.availabilityHistoryChartRef.setLoading(true);
    const grouping: {unit: string; binSize: number} = {unit: 'minute', binSize: 10};
    if (this.extremes.end?.diff(this.extremes.start, 'months', true) >= 0.9) {
      grouping.unit = 'day';
      grouping.binSize = 1;
    } else if (this.extremes.end?.diff(this.extremes.start, 'weeks', true) >= 0.9) {
      grouping.unit = 'hour';
      grouping.binSize = 1;
    } else {
      grouping.unit = 'minute';
      grouping.binSize = 10;
    }
    const filterWithGrouping = merge({groupingUnit: grouping.unit, groupingBinSize: grouping.binSize}, filter);
    const data = await lastValueFrom(this.equipmentFailureService.getChartRangeData(filterWithGrouping));
    if (echo !== this.loadDataEcho) return;
    const filteredData = data.filter(d => this.extremes.start.isBefore(d.name) && this.extremes.end.isAfter(d.name));

    this.failureHistoryChartRef.setSerieData(
      map(filteredData, d => [d.name, d.value]),
      0
    );
    this.failureHistoryChartRef.setSerieData(
      map(filteredData, d => [d.name, d.opened]),
      1
    );

    this.availabilityHistoryChartRef.setSerieData(
      map(filteredData, d => [d.name, 100 - (d.value / this.elevatorCount) * 100]),
      0
    );

    this.failureHistoryChartRef.setExtremes(this.extremes.start, this.extremes.end);
    this.failureHistoryChartRef.setLoading(false);
    this.availabilityHistoryChartRef.setExtremes(this.extremes.start, this.extremes.end);
    this.availabilityHistoryChartRef.setLoading(false);
  }

  noflowCount: number | null;
  private async loadNoflowCount(echo: number, filter: any) {
    this.noflowCount = null;
    const count = get(
      await lastValueFrom(
        this.equipmentStatusHistoryService.count(
          merge(
            {field: EquipmentStatusHistoryField.SIGNATURE_FLOW_STATE, statuses: [SignatureFlowState.NOFLOW]},
            filter
          )
        )
      ),
      'count'
    );
    if (echo !== this.loadDataEcho) return;
    this.noflowCount = count;
  }

  newFailureCount: number | null;
  private async loadNewFailureRate(echo: number, filter: any) {
    this.newFailureCount = null;
    const count = get(await lastValueFrom(this.equipmentFailureService.getCount(merge({new: true}, filter))), 'count');
    if (echo !== this.loadDataEcho) return;
    this.newFailureCount = count;
  }

  failureCount: number | null;
  failureAvgDuration: number;
  private async loadFailureRate(echo: number, filter: any) {
    this.failureCount = null;
    this.failureAvgDuration = null;
    const data = await lastValueFrom(this.equipmentFailureService.getCount(filter));
    if (echo !== this.loadDataEcho) return;
    this.failureCount = get(data, 'count');
    this.failureAvgDuration = get(data, 'avgDuration');
  }

  disponibilityRate: number | null;
  private async loadDisponibilityRate(echo: number, filter: any) {
    this.disponibilityRate = null;
    if (!filter.start || !filter.end) return;
    const count = get(await lastValueFrom(this.equipmentAvailabilityService.getCount(filter)), 'count');
    if (echo !== this.loadDataEcho) return;
    this.disponibilityRate = count;
  }

  private async loadContractorPerfChartData(echo: number, filter: any, data: any[]) {
    if (echo !== this.loadDataEcho) return;
    const nbFailures = reduce(data[0], (pv, cv) => pv + cv.value, 0);
    const nbElevator = reduce(data[1], (pv, cv) => pv + cv.value, 0);
    const contractorNames = map(
      lfilter(this.contractors, contractor => includes(filter.contractorIds, contractor.value)),
      'name'
    );
    this.contractorPerfChartRef.setXCategories(contractorNames);
    const series = [
      {
        name: `Ascenseurs (${nbElevator})`,
        color: Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0.6).get('rgba'),
        data: map(contractorNames, name => ({
          value: get(find(data[1], {name}), 'value', 0),
          y: multiply(divide(get(find(data[1], {name}), 'value', null), nbElevator), 100),
        })),
        pointPadding: 0.3,
        zIndex: 2,
      },
      {
        name: `Pannes (${nbFailures})`,
        color: 'var(--ion-color-danger)',
        data: map(contractorNames, name => ({
          value: get(find(data[0], {name}), 'value', 0),
          y: multiply(divide(get(find(data[0], {name}), 'value', null), nbFailures), 100),
        })),
        pointPadding: 0.4,
        zIndex: 1,
      },
    ];
    this.contractorPerfChartRef.setSeries(series as any);
    this.contractorPerfChartRef.setLoading(false);
  }

  private async loadCallByContractorChartData(echo: number, filter: any) {
    this.callByContractorChartRef.setLoading(true);
    return lastValueFrom(this.equipmentReportService.getCountByContractor(filter)).then(data => {
      if (echo !== this.loadDataEcho) return;
      const series = [
        {
          name: 'Ascensoristes',
          colorByPoint: true,
          data: map(orderBy(data, ['name']), (d, index) => ({
            index,
            name: d.name,
            y: d.value,
            color: this.contractorColors[d.name]?.color,
          })),
        },
      ];
      this.callByContractorChartRef.setSeries(series as any);
      this.callByContractorChartRef.setLoading(false);
      return data;
    });
  }

  private async loadFailureByContractorChartData(echo: number, filter: any) {
    this.failureByContractorChartRef.setLoading(true);
    return lastValueFrom(this.equipmentFailureService.getCountByContractor(filter)).then(data => {
      if (echo !== this.loadDataEcho) return;
      const series = [
        {
          name: 'Ascensoristes',
          colorByPoint: true,
          data: map(orderBy(data, ['name']), (d, index) => ({
            index,
            name: d.name,
            y: d.value,
            color: this.contractorColors[d.name]?.color,
          })),
        },
      ];
      this.failureByContractorChartRef.setSeries(series as any);
      this.failureByContractorChartRef.setLoading(false);
      return data;
    });
  }

  elevatorCount: number | null = null;
  private async loadElevatorByContractorChartData(echo: number, filter: any) {
    this.elevatorByContractorChartRef.setLoading(true);
    this.elevatorCount = null;
    return lastValueFrom(this.equipmentService.countByContractor(filter)).then(data => {
      if (echo !== this.loadDataEcho) return;
      this.elevatorCount = 0;
      const series = [
        {
          name: 'Ascensoristes',
          colorByPoint: true,
          data: map(orderBy(data, ['name']), (d, index) => {
            this.elevatorCount += d.value;
            return {
              index,
              name: d.name,
              y: d.value,
              color: this.contractorColors[d.name]?.color,
            };
          }),
        },
      ];
      this.elevatorByContractorChartRef.setSeries(series as any);
      this.elevatorByContractorChartRef.setLoading(false);
      return data;
    });
  }

  trafficCount: number | null = null;
  private async loadTrafficChartData(echo: number, filter: any) {
    this.trafficChartRef.setLoading(true);
    this.trafficCount = null;
    const unitGrouping = this.extremes.end?.diff(this.extremes.start, 'months') > 1 ? 'day' : 'hour';
    await lastValueFrom(
      this.sensorDataService.getChartRangeData(merge({unitGrouping, kinds: [Metric.MOTION_COUNT]}, filter))
    )
      .then(data => (echo !== this.loadDataEcho ? [] : get(data, Metric.MOTION_COUNT)))
      .then(data => consolidateDayData(this.extremes, unitGrouping, data))
      .then(data => {
        this.trafficCount = data.count;
        if (this.trafficChartRef.chart) {
          this.trafficChartRef.chart.yAxis[0].setExtremes(0, unitGrouping === 'hour' ? 23 : 0);
          (this.trafficChartRef.chart as any).colorAxis[0].update({max: data.maxValue});
        }
        this.trafficChartRef.setSerieData(data.values);
        this.trafficChartRef.setLoading(false);
      });
  }

  private async loadFailureChartData(echo: number, filter: any) {
    this.failureChartRef.setLoading(true);
    const unit = 'hour';
    return lastValueFrom(
      this.equipmentStatusHistoryService.getChartRangeData(
        merge(
          {
            field: EquipmentStatusHistoryField.SIGNATURES_STATUS,
            statuses: [SignatureStatus.OFFLINE],
            unit,
            binSize: 1,
          },
          filter
        )
      )
    )
      .then(data => (echo !== this.loadDataEcho ? [] : get(data, SignatureStatus.OFFLINE)))
      .then(data => consolidateDayData(this.extremes, unit, data))
      .then(data => {
        (this.failureChartRef.chart as any)?.colorAxis[0].update({max: data.maxValue});
        this.failureChartRef.setSerieData(data.values);
        this.failureChartRef.setLoading(false);
      });
  }

  private async loadFailureDayChartData(echo: number, filter: any) {
    this.failureDayChartRef.setLoading(true);
    return lastValueFrom(this.equipmentFailureService.byHourOfDayOfWeek(filter)).then(data => {
      if (echo !== this.loadDataEcho) return;
      this.failureDayChartRef.setSerieData(
        reduce(
          data,
          (serieData, dayData, dayIndex) => {
            serieData.push({
              id: dayData._id,
              name: this.DAYS[dayData.day - 1],
              value: dayData.count,
            });
            each(dayData.hours, (hourData, hourIndex) =>
              serieData.push({
                parent: dayData._id,
                id: hourData._id,
                name: `${hourData.hour}H`,
                value: hourData.count,
                colorValue: hourData.count,
              })
            );
            return serieData;
          },
          []
        )
      );
      this.failureDayChartRef.setLoading(false);
    });
  }

  // private consolidateTrafficData(
  //   unitGrouping: 'day' | 'hour',
  //   data: AggregationValue[]
  // ): { count: number; maxValue: number; values: any[] } {
  //   if (!data?.length) return { count: 0, maxValue: 0, values: [] };
  //   const values = [];
  //   let count = 1;
  //   let maxValue = 1;
  //   let startIndex = 0;
  //   let columnIndex = 0;
  //   for (
  //     const start = this.extremes.start.clone().startOf('day');
  //     start.isBefore(this.extremes.end);
  //     start.add(1, unitGrouping)
  //   ) {
  //     let entry;
  //     let i = 0;
  //     for (let d of data.slice(startIndex)) {
  //       if (d.name === start.valueOf()) {
  //         entry = d;
  //         startIndex += i + 1;
  //         break;
  //       } else if (startIndex && d.name < start.valueOf()) {
  //         break;
  //       }
  //       ++i;
  //     }
  //     values.push([
  //       moment(get(entry, 'name', start.valueOf())).startOf('day').valueOf(),
  //       unitGrouping === 'hour' ? start.hour() : 0,
  //       get(entry, 'value', 0),
  //     ]);
  //     count += get(entry, 'value', 0);
  //     maxValue = Math.max(maxValue, get(entry, 'value', 0));
  //     ++columnIndex;
  //   }
  //   return { count, maxValue, values };
  // }

  private buildPieChartOptions(): any {
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: '',
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen',
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
              'downloadCSV',
              'downloadXLS',
              'openInCloud',
            ],
          },
        },
      },
      credits: {enabled: false},
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b> ({point.y})',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          },
        },
      },
      series: [],
    };
  }

  private buildStackChartOptions(): any {
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'column',
      },
      title: '',
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen',
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
              'downloadCSV',
              'downloadXLS',
              'openInCloud',
            ],
          },
        },
      },
      credits: {enabled: false},
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      legend: {
        shadow: false,
      },
      tooltip: {
        shared: true,
        split: false,
        useHTML: true,
        headerFormat: '<small>{point.key}</small><br/>',
        pointFormat:
          '<span style="color:{point.color}">\u25CF</span> ' +
          '{series.name}: <b>{point.y:.1f} % ({point.value})</b><br/>',
      },
      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            useHtml: true,
            format: '{point.y:.1f} % ({point.value})',
            // padding: 10,
            // allowOverlap: true
          },
        },
      },
      yAxis: {
        title: null,
        labels: {
          format: '{value:.0f} %',
        },
      },
      series: [],
    };
  }

  private buildTemperatureChartOptions(kind: 'traffic' | 'failure'): any {
    return {
      chart: {
        type: 'heatmap',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
      },
      title: '',
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen',
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
              'downloadCSV',
              'downloadXLS',
              'openInCloud',
            ],
          },
        },
      },
      credits: {enabled: false},
      boost: {
        useGPUTranslations: true,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          align: 'left',
          x: 5,
          y: 14,
          format: '{value:%d %b}', // long month
        },
        showLastLabel: false,
        tickLength: 10,
      },

      yAxis: {
        title: {
          text: null,
        },
        labels: {
          format: '{value}:00',
        },
        minPadding: 0,
        maxPadding: 0,
        startOnTick: false,
        endOnTick: false,
        tickPositions: [0, 6, 12, 18, 24],
        tickWidth: 1,
        min: 0,
        max: 23,
      },

      colorAxis: {
        stops: [
          [0, '#ffffff'],
          [1, kind === 'traffic' ? '#21698e' : '#e9396b'],
        ],
        min: 0,
        startOnTick: false,
        endOnTick: false,
        labels: {
          format: '{value}',
        },
      },
      series: [
        {
          boostThreshold: 100,
          borderWidth: 0,
          nullColor: '#EFEFEF',
          colsize: 24 * 36e5, // one day
          tooltip: {
            headerFormat: kind === 'traffic' ? 'Fréquentation<br/>' : 'Pannes<br/>',
            pointFormat: '{point.x:%e %b, %Y} {point.y}:00: <b>{point.value}</b>',
          },
        },
      ],
    };
  }

  private buildTreemapChartOptions(): any {
    return {
      chart: {
        events: {},
      },
      title: '',
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen',
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
              'downloadCSV',
              'downloadXLS',
              'openInCloud',
            ],
          },
        },
      },
      credits: {enabled: false},
      colorAxis: {
        min: 0,
        minColor: '#FFFFFF',
        maxColor: '#e9396b',
      },
      series: [
        {
          name: this.translate.instant('week'),
          type: 'treemap',
          layoutAlgorithm: 'squarified',
          allowDrillToNode: true,
          levelIsConstant: false,
          dataLabels: {
            enabled: false,
          },
          levels: [
            {
              level: 1,
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: '14px',
                  textOutline: 'none',
                },
              },
              borderWidth: 1,
              borderColor: '#21698e',
            },
            {
              level: 2,
              borderWidth: 2,
              dataLabels: {
                enabled: true,
                color: '#666',
                style: {
                  fontSize: '10px',
                  textOutline: 'none',
                },
              },
              borderColor: '#fffff',
            },
          ],
          accessibility: {
            exposeAsGroupOnly: true,
          },
        },
      ],
    };
  }

  private buildAvailabilityHistoryChartOptions(): any {
    return {
      chart: {zoomType: 'x'},
      credits: {enabled: false},
      exporting: {enabled: false},
      title: {text: ''},
      rangeSelector: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        split: false,
        useHTML: true,
        valueSuffix: '%',
      },
      legend: {
        enabled: true,
      },
      xAxis: {type: 'datetime', ordinal: false},
      yAxis: [
        {
          showLastLabel: true,
          opposite: false,
          lineColor: 'var(--ion-color-primary)',
          labels: {style: {color: 'var(--ion-color-primary)'}},
          lineWidth: 1,
          resize: {enabled: true},
          max: 100,
          min: 0,
          tooltipValueFormat: '{value:.2f}%',
        },
      ],
      plotOptions: {
        series: {
          dataGrouping: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: 'Disponibilité',
          type: 'area',
          threshold: null,
          step: true,
          tooltip: {
            valueDecimals: 2,
          },
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
            ],
          },
        } as any,
      ],
    };
  }

  private buildFailureHistoryChartOptions(): any {
    return {
      chart: {zoomType: 'x'},
      credits: {enabled: false},
      exporting: {enabled: false},
      title: {text: ''},
      rangeSelector: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        split: false,
        useHTML: true,
      },
      legend: {
        enabled: true,
      },
      xAxis: {type: 'datetime', ordinal: false},
      yAxis: [
        {
          title: {text: 'Pannes cumulées', style: {color: '#e9396b'}},
          showLastLabel: true,
          opposite: false,
          lineColor: '#e9396b',
          labels: {style: {color: '#e9396b'}},
          lineWidth: 1,
          resize: {enabled: true},
          min: 0,
        },
        {
          title: {text: 'Nouvelles pannes', style: {color: 'rgba(240, 119, 153)'}},
          showLastLabel: true,
          opposite: true,
          lineColor: 'rgba(240, 119, 153, 0.50)',
          labels: {style: {color: 'rgba(240, 119, 153)'}},
          lineWidth: 1,
          resize: {enabled: true},
          min: 0,
          tooltipValueFormat: '{value:.0f}',
        },
      ],
      plotOptions: {
        series: {
          dataGrouping: {
            enabled: false,
          },
        },
      },
      series: [
        {
          type: 'line',
          step: true,
          name: 'Pannes cumulées',
          yAxis: 0,
          color: SignatureStatusColor.offline,
        },
        {
          type: 'area',
          step: true,
          name: 'Nouvelles pannes',
          yAxis: 1,
          color: 'rgba(240, 119, 153, 0.50)',
          opacity: 0.6,
        },
      ],
    };
  }
}
