/** @format */

import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {cloneDeep, get, isEqual, map, reduce, set} from 'lodash-es';
import moment from 'moment';
import {NGXLogger} from 'ngx-logger';
import {
  AccessControlStatus,
  ChartComponent,
  EquipmentStatusOptions,
  Family,
  IEquipmentStatusOption,
  ValueAndTrend,
  ValueInfo,
  fadeOut,
} from 'sesio-lib';
import {SubSink} from 'subsink';
import {convertHeaderSelection} from '../../../_classes/header-search';
import {AccessControlService} from '../../../_services/access-control.service';
import {EquipmentService, IEquipmentFilter} from '../../../_services/equipment.service';
import {IHeaderSelection, SessionService} from '../../../_services/session.service';

@Component({
  selector: 'app-equipment-stats',
  templateUrl: './equipment-stats-block.component.html',
  styleUrls: ['./equipment-stats-block.component.scss'],
  animations: [fadeOut],
})
export class EquipmentStatsComponent implements OnInit, OnDestroy {
  public accessControlStatus = [
    {
      status: AccessControlStatus.CREATION,
      name: `request-${AccessControlStatus.CREATION}`,
      color: '#92949c',
    },
    {
      status: AccessControlStatus.NEW,
      name: `request-${AccessControlStatus.NEW}`,
      color: '#10487F',
    },
    {
      status: AccessControlStatus.QUALIFIED,
      name: `request-${AccessControlStatus.QUALIFIED}`,
      color: '#346DA4',
    },
    {
      status: AccessControlStatus.PRE_PLANNED,
      name: `request-${AccessControlStatus.PRE_PLANNED}`,
      color: '#5991C8',
    },
    {
      status: AccessControlStatus.PLANNED,
      name: `request-${AccessControlStatus.PLANNED}`,
      color: '#7CB5EC',
    },
    {
      status: AccessControlStatus.REALIZED,
      name: `request-${AccessControlStatus.REALIZED}`,
      color: '#9FD9FF',
    },
  ];

  @Input()
  public shrink = false;

  @Input()
  public family: string;

  @Input('equipmentId')
  public set setEquipmentId(equipmentId: string) {
    if (!equipmentId) {
      equipmentId = null;
    }
    if (!isEqual(this.equipmentId, equipmentId)) {
      this.equipmentId = equipmentId;
      this.load();
    }
  }

  public equipmentId: string = null;

  public state: number;

  public nbBreakdownLabel: string;

  public data: {
    statuses: (IEquipmentStatusOption & {count?: number})[];
    equipmentCount: number;
    failureRate: {loading: boolean; value?: ValueInfo; error?: string};
    availabilityRate: {
      loading: boolean;
      value?: ValueInfo;
      error?: string;
    };
    qualityIndex: {loading: boolean; value?: ValueAndTrend; error?: string};
    controlRate: {loading: boolean; value?: ValueAndTrend; error?: string};
    accessControl?: {
      loading: boolean;
      countByStatus: {[status: string]: number};
    };
  };

  public accessControlChartOptions: Highcharts.Options;
  @ViewChild('accessControlChart', {static: false, read: ChartComponent})
  public accessControlChartRef: ChartComponent;

  private initialized = true;
  private loading: any;

  private filter: IEquipmentFilter;
  private subsink = new SubSink();

  constructor(
    private router: Router,
    private logger: NGXLogger,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private sessionService: SessionService,
    private equipmentService: EquipmentService,
    private accessControlService: AccessControlService
  ) {
    this.clear();
  }

  ngOnInit(): void {
    this.subsink.add(this.sessionService.$headerSelection.subscribe(data => this.loadFilter(data)));
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  openStatus(status: string) {
    switch (this.family) {
      case Family.ELEVATOR:
      case Family.PARKING_DOOR:
        return this.router.navigate([`/${this.family}-space`], {
          queryParams: {status},
        });
    }
  }

  private loadFilter(selection: IHeaderSelection): void {
    const filter: any = convertHeaderSelection(selection);
    if (isEqual(this.filter, filter)) return;
    this.filter = filter;
    this.load();
  }

  private async load(): Promise<void> {
    this.filter.families = [this.family];
    this.filter.equipmentId = this.equipmentId;
    this.clear();
    this.state = Math.random() * 5;
    const promises = [this.loadStatus()];
    switch (this.family) {
      case Family.FIRE_SAFETY:
        promises.push(this.loadQualityIndex());
        promises.push(this.loadControlRate());
        break;
      case Family.ACCESS_CONTROL:
        this.buildStackedColumnChartOptions();
        promises.push(this.loadAccessControlData());
      default:
        promises.push(this.loadFailureRate());
        promises.push(this.loadAvailabilityRate());
    }
    Promise.all(promises).finally(() => this.changeDetectorRef.detectChanges());
  }

  private clear(): void {
    this.data = {
      // status: { loading: true, total: get(this.data, 'status.total', 0), value: clone(this.status) },
      statuses: [],
      equipmentCount: 0,
      failureRate: {loading: true},
      availabilityRate: {loading: true},
      qualityIndex: {loading: true},
      controlRate: {loading: true},
    };
  }

  private async loadStatus(): Promise<void> {
    this.data.statuses = cloneDeep(EquipmentStatusOptions);
    const status = await this.equipmentService.getStatus(this.filter).toPromise();
    this.data.statuses.forEach(s => ((s.count = get(status, s.value, 0)), (this.data.equipmentCount += s.count)));
  }

  private async loadAccessControlData(): Promise<void> {
    this.accessControlChartRef?.setLoading(true);
    set(this.data, 'accessControl.loading', true);
    this.data.accessControl = {
      loading: false,
      countByStatus: reduce(
        await this.accessControlService.countByStatus(this.filter).toPromise(),
        (pv, cv) => ((pv[cv.name] = cv.value), pv),
        {}
      ),
    };
    const months = new Array(12).fill(null);
    const evolutionSeries: any[] = map(await this.accessControlService.getEvolution(this.filter).toPromise(), d => {
      const name = d.kind.translate ? this.translate.instant(d.kind.name) : d.kind.name;
      return {
        type: 'column',
        name,
        data: months.map((m, i) => d.data[(i + 1).toString()] || null),
      };
    });
    this.accessControlChartRef.setSeries(evolutionSeries);
    this.accessControlChartRef.setLoading(false);
  }

  private async loadFailureRate(): Promise<void> {
    this.data.failureRate.loading = true;
    this.data.failureRate.value = null;
    this.data.failureRate.error = null;
    try {
      const breakdownRate = await this.equipmentService.getFailureRate(this.filter).toPromise();
      this.data.failureRate.value = breakdownRate;
      if (this.data.failureRate.value) {
        this.nbBreakdownLabel = await this.translate
          .get('nb-breakdown' + (this.data.failureRate.value.value > 1 ? 's' : ''), {
            nb: this.data.failureRate.value.value,
          })
          .toPromise();
      } else {
        this.nbBreakdownLabel = null;
      }
    } catch (err) {
      this.logger.error(err);
      this.data.failureRate.error = err.error ? err.error.message : err;
    } finally {
      this.data.failureRate.loading = false;
    }
  }

  private async loadAvailabilityRate(): Promise<void> {
    this.data.availabilityRate.loading = true;
    this.data.availabilityRate.value = null;
    this.data.availabilityRate.error = null;
    try {
      const availabilityRate = await this.equipmentService.getAvailabilityRate(this.filter).toPromise();
      this.data.availabilityRate.value = availabilityRate;
    } catch (err) {
      this.logger.error(err);
      this.data.availabilityRate.error = err.error ? err.error.message : err;
    } finally {
      this.data.availabilityRate.loading = false;
    }
  }

  private async loadQualityIndex(): Promise<void> {
    this.data.qualityIndex.loading = true;
    this.data.qualityIndex.value = null;
    this.data.qualityIndex.error = null;
    setTimeout(() => {
      this.data.qualityIndex.value = null;
      this.data.qualityIndex.loading = false;
    }, 300);
  }

  private async loadControlRate(): Promise<void> {
    this.data.controlRate.loading = true;
    this.data.controlRate.value = null;
    this.data.controlRate.error = null;
    setTimeout(() => {
      this.data.controlRate.value = null;
      this.data.controlRate.loading = false;
    }, 300);
  }

  private buildStackedColumnChartOptions(): void {
    this.accessControlChartOptions = {
      chart: {
        type: 'column',
        spacingBottom: 5,
      },
      credits: {enabled: false},
      exporting: {enabled: false},
      title: {text: ''},
      series: [],
      xAxis: {categories: moment.monthsShort(), labels: {rotation: -45}},
      yAxis: [
        {
          min: 0,
          stackLabels: {enabled: true},
          title: {
            text: this.translate.instant('request-total'),
          },
        },
        {
          opposite: true,
          title: {
            text: this.translate.instant('request-realization-delay'),
          },
          labels: {style: {color: '#FF0706'}},
        },
      ],
      legend: {
        enabled: true,
        floating: false,
        verticalAlign: 'top',
        align: 'center',
        alignColumns: false,
        itemDistance: 3,
        margin: 0,
        padding: 0,
        symbolPadding: 0,
        y: -10,
        x: -13,
        itemMarginTop: 8,
        itemStyle: {fontSize: '8px'},
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
      },
      plotOptions: {
        column: {stacking: 'normal', dataLabels: {enabled: true}},
      },
    };
  }
}
