/** @format */

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {isNil} from 'lodash-es';
import {ModelMapper} from 'model-mapper';
import {Moment} from 'moment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AggregationValue} from '../classes/aggregation';
import {EquipmentKind} from '../classes/equipment-kind';
import {EquipmentStatusHistory, EquipmentStatusHistoryField} from '../classes/equipment-status-history';
import {LibConfig, LibConfigService} from '../config.service';

export interface IFilter {
  organizationalUnit?: {
    id: string;
    name: string;
    pathNames: string[];
  };
  perimeter?: boolean;

  equipmentId?: string;
  families?: string[];
  contractorIds?: string[];
  start?: Moment;
  end?: Moment;
  field?: EquipmentStatusHistoryField;
  statuses: string[];
  unit?: string;
  binSize?: number;
  equipmentKind?: EquipmentKind;
  signatureSettingName?: string;
  isTertiary?: boolean;
  supervised?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentStatusHistoryService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public getEquipmentStatuses(
    id: string,
    {start, end}: {start: Moment; end: Moment}
  ): Observable<EquipmentStatusHistory[]> {
    const params: any = {};
    if (start) params.start = start.toISOString();
    if (end) params.end = end.toISOString();
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/equipment-status-history/equipment/${id}`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(EquipmentStatusHistory).map(d))));
  }

  public getByUnitData(search: IFilter): Observable<{[key: string]: AggregationValue[]}> {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/equipment-status-history/get-by-unit-data`,
      this.buildSearchFilter(search)
    );
  }

  public getChartRangeData(search: IFilter): Observable<{[key: string]: AggregationValue[]}> {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/equipment-status-history/get-chart-range-data`,
      this.buildSearchFilter(search)
    );
  }

  protected buildSearchFilter(search: IFilter): any {
    const filter: any = {};
    if (search?.perimeter) {
      filter.perimeter = true;
    } else if (search?.organizationalUnit) {
      filter.organizationalUnitId = search.organizationalUnit.id;
    }
    if (search?.equipmentId) filter.equipmentId = search.equipmentId;
    if (search?.families) filter.families = search.families;
    if (search?.contractorIds) filter.contractorIds = search.contractorIds;
    if (search?.start) filter.start = search.start.toISOString();
    if (search?.end) filter.end = search.end.toISOString();
    if (search?.field) filter.field = search.field;
    if (search?.statuses?.length) filter.statuses = search.statuses;
    if (search?.unit) filter.unit = search.unit;
    if (search?.binSize) filter.binSize = search.binSize;
    if (search?.equipmentKind) filter.equipmentKindId = search.equipmentKind.id;
    if (search?.signatureSettingName) filter.signatureSettingName = search.signatureSettingName;
    if (search?.isTertiary === true) filter.isTertiary = search.isTertiary;
    if (!isNil(search?.supervised)) filter.supervised = search.supervised;
    return filter;
  }
}
