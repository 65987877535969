/** @format */

import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {clone, isNil} from 'lodash-es';
import {v4 as uuidv4} from 'uuid';

import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Boost from 'highcharts/modules/boost';
import ExportData from 'highcharts/modules/export-data';
import Exporting from 'highcharts/modules/exporting';
import noData from 'highcharts/modules/no-data-to-display';
import {IChartData, IChartLabels} from '../chart.interface';
declare var require: any;
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);

import Drilldown from 'highcharts/modules/drilldown';
import {fadeOut} from '../../../constants/animations';
import {highchartsOptions} from '../../../constants/highcharts';

Drilldown(Highcharts);

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'sesio-column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['./column-chart.component.scss'],
  animations: [fadeOut],
  encapsulation: ViewEncapsulation.None,
})
export class ColumnChartComponent implements OnInit {
  public id = `column-chart-${uuidv4()}`;

  public loading = true;

  @Input('loading')
  public loadingData = true;

  @Input()
  private hasTrend = false;

  @Input()
  private labels: IChartLabels = {} as IChartLabels;

  @Input()
  public format = '.2f';

  @Input()
  public suffix = '';

  @Input()
  public trendSuffix = '';

  @Input()
  public color = '#4572A7';

  @Input()
  public colorByPoint = false;

  @Input()
  public plot = false;

  @Input()
  public plotColor = '#666666';

  @Input()
  public trendColor = '#FF9900';

  @Input()
  public min: number;

  @Input()
  public max: number;

  @Input()
  public hideAxisTitle = false;

  @Input()
  public hideLegend = false;

  @Input('config')
  public set setConfig(config: any) {
    if (config && config.hasTrend) {
      this.hasTrend = config.hasTrend;
    }
    if (config && config.labels) {
      this.labels = config.labels;
    }
    if (config && config.format) {
      this.format = config.format;
    }
    if (config && config.suffix) {
      this.suffix = config.suffix;
    }
    if (config && config.trendSuffix) {
      this.trendSuffix = config.trendSuffix;
    }
    if (config && config.color) {
      this.color = config.color;
    }
    if (config && config.colorByPoint) {
      this.colorByPoint = config.colorByPoint;
    }
    if (config && config.plot) {
      this.plot = config.plot;
    }
    if (config && config.plotColor) {
      this.plotColor = config.plotColor;
    }
    if (config && config.trendColor) {
      this.trendColor = config.trendColor;
    }
    if (config && config.min) {
      this.min = config.min;
    }
    if (config && config.max) {
      this.max = config.max;
    }
    if (config && !isNil(config.hideAxisTitle)) {
      this.hideAxisTitle = config.hideAxisTitle;
    }
    if (config && !isNil(config.hideLegend)) {
      this.hideLegend = config.hideLegend;
    }
  }

  @Input('data')
  public set setData(data: IChartData[]) {
    this.updateData(data);
  }

  private data: IChartData[] = [];

  private trend: IChartData[] = [];

  private chart: Highcharts.Chart;

  private nameLabel: string;
  private trendLabel: string;

  private drilldowStep = [];

  constructor(private translate: TranslateService) {}

  async ngOnInit(): Promise<void> {
    Highcharts.setOptions(highchartsOptions[this.translate.getDefaultLang()]);
    this.nameLabel = await this.translate.get(this.labels.column).toPromise();
    this.trendLabel = await this.translate.get(this.labels.trend || 'trend').toPromise();
  }

  public async loadChart($event: any): Promise<void> {
    if (!$event.visible || !this.loading) {
      return;
    }
    this.loading = false;
    const series: any = [
      {
        name: this.nameLabel,
        type: 'column',
        yAxis: this.hasTrend ? 1 : 0,
        data: this.data,
        color: this.color,
        colorByPoint: this.colorByPoint,
      },
    ];
    const yAxis: any[] = [
      {
        title: {text: this.hideAxisTitle ? '' : this.nameLabel, style: {color: this.color}},
        labels: {style: {color: this.color, format: `{value}${this.suffix || ''}`}},
        opposite: this.hasTrend,
      },
    ];
    if (this.min) {
      yAxis[0].min = this.min;
    }
    if (this.max) {
      yAxis[0].max = this.max;
    }
    if (this.hasTrend) {
      this.trend = this.getTrend(this.data);
      series.push({name: this.trendLabel, color: this.trendColor, type: 'spline', data: this.trend});
      yAxis.splice(0, 0, {
        title: {text: this.hideAxisTitle ? '' : this.trendLabel, style: {color: this.trendColor}},
        labels: {style: {color: this.trendColor, format: `{value}${this.trendSuffix || ''}`}},
      });
    }
    this.chart = Highcharts.chart(this.id, {
      chart: {
        type: 'column',
        zoomType: 'xy',
        events: {
          drilldown: e => this.triggerDrilldown(e),
          drillupall: e => {
            this.drilldowStep.pop();
            this.chart.setTitle({text: this.title()});
          },
        },
      },
      credits: false,
      title: {text: ''},
      legend: {enabled: !this.hideLegend},
      xAxis: {type: 'category'},
      yAxis,
      series,
      tooltip: {
        shared: true,
        valueSuffix: this.suffix || '',
        pointFormat: `<span style="color:{series.color}">{series.name}</span>: <b>{point.y:${this.format}}${
          this.suffix || ''
        }</b><br/>`,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: this.plot,
            format: `{point.y:${this.format}}${this.suffix}`,
            color: this.plotColor,
          },
        },
      },
      drilldown: {
        // allowPointDrilldown: false,
        activeAxisLabelStyle: {
          color: 'var(--ion-color-primary)',
          textDecoration: 'none',
        },
        drillUpButton: {
          relativeTo: 'spacingBox',
          position: {y: -10, x: -30},
          theme: {
            class: 'drilldown-back-btn',
            fill: 'var(--ion-color-primary)',
            'stroke-width': 0,
            r: 8,
            height: 24,
            style: {
              color: 'white',
            },
            states: {
              hover: {fill: 'var(--ion-color-primary-tint)'},
              select: {fill: 'var(--ion-color-primary-tint)'},
            },
          },
        },
      },
    } as any);
  }

  private async triggerDrilldown(event: any): Promise<void> {
    if (event.seriesOptions) return;
    const option: IChartData = event.point.options;
    if (!option.asyncDrilldown) return;
    this.chart.showLoading();
    this.drilldowStep.push(option.drilldown);
    (option as any).drilldownData = option.asyncDrilldown(option.drilldown);
    const data = await (option as any).drilldownData;
    (this.chart as any).addSingleSeriesAsDrilldown(event.point, {
      name: `${this.nameLabel} (${this.title()})`,
      color: this.color,
      type: 'column',
      yAxis: this.hasTrend ? 1 : 0,
      data,
    } as any);
    if (this.hasTrend) {
      (this.chart as any).addSingleSeriesAsDrilldown(event.point, {
        name: `${this.trendLabel} (${this.title()})`,
        color: this.trendColor,
        type: 'spline',
        data: this.getTrend(data),
      } as any);
    }
    (this.chart as any).applyDrilldown();
    this.chart.setTitle({text: this.title()});
    this.chart.hideLoading();
  }

  private updateData(data: IChartData[]): void {
    this.data = [];
    this.trend = [];
    if (data) {
      data.forEach(d => {
        const nd = clone(d);
        if (nd.drilldown) {
          nd.name = d.name;
        }
        this.data.push(nd);
      });
      if (this.hasTrend) {
        this.trend = this.getTrend(this.data);
      }
    }
    if (this.chart) {
      this.chart.series[this.hasTrend ? 1 : 0].setData(this.data);
      if (this.hasTrend) {
        this.chart.series[0].setData(this.trend);
      }
      this.chart.redraw();
    }
    setTimeout(() => (this.loadingData = !data));
  }

  private getTrend(data: IChartData[]): IChartData[] {
    const trend = [];
    let amount = 0;
    data.forEach((d, i) => {
      trend.push({name: d.name, y: ((amount += d.y) / (i + 1)) * data.length});
    });
    return trend;
  }

  private title(): string {
    return this.drilldowStep.length ? this.drilldowStep.join(' ') : '';
  }
}
