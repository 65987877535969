/** @format */

import {Component, Input} from '@angular/core';

@Component({
  selector: 'sesio-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input('backdrop')
  set setBackdrop(backdrop: any) {
    this.backdrop = backdrop !== false;
  }
  public backdrop = false;
}
