/** @format */

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {TranslateModule} from '@ngx-translate/core';
import {IntersectionObserverModule} from 'ngx-intersection-observer';
import {NgPipesModule} from 'ngx-pipes';
import {PipesModule} from 'sesio-lib';
import {EquipmentKindSelectComponent} from './equipment-kind-select.component';

@NgModule({
  declarations: [EquipmentKindSelectComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    FlexLayoutModule,
    IntersectionObserverModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatInputModule,
    TranslateModule.forChild(),
    PipesModule,
    NgPipesModule,
    MatChipsModule,
  ],
  exports: [EquipmentKindSelectComponent],
})
export class EquipmentKindSelectModule {}
