/** @format */

import {propertyMap} from 'model-mapper';
import {Moment} from 'moment';
import {EmbededUser} from './user';
import {File} from './file';
import {EmbededOrganizationalUnit} from './embeded-organizational-unit';
import {UnitPriceSchedule} from './unit-price-schedule';
import {EmbededEsi} from './esi';

export enum AccessControlStatus {
  CREATION = 'creation',
  WAITING_PURCHASE_ORDER_GENERATION = 'waiting-purchase-order-generation',
  ERROR_PURCHASE_ORDER_REQUEST = 'error-purchase-order-request',
  ERROR_PURCHASE_ORDER_GENERATION = 'error-purchase-order-generation',
  NEW = 'new',
  QUALIFIED = 'qualified',
  PRE_PLANNED = 'pre-planned',
  PLANNED = 'planned',
  REALIZED = 'realized',
  VALIDATED = 'validated',
  CANCELED = 'canceled',
}

export const AccessControlStatusName: {[key: string]: string} = {
  [AccessControlStatus.CREATION]: 'Création',
  [AccessControlStatus.WAITING_PURCHASE_ORDER_GENERATION]: 'Génération du bon de commande en cours',
  [AccessControlStatus.ERROR_PURCHASE_ORDER_REQUEST]: 'Erreur lors de la demande du bon de commande',
  [AccessControlStatus.ERROR_PURCHASE_ORDER_GENERATION]: 'Erreur RIVP ULIS lors de la génération du bon de commande',
  [AccessControlStatus.NEW]: 'Nouvelle',
  [AccessControlStatus.QUALIFIED]: 'Qualifiée',
  [AccessControlStatus.PRE_PLANNED]: 'Pré-planifiée',
  [AccessControlStatus.PLANNED]: 'Planifiée',
  [AccessControlStatus.REALIZED]: 'Réalisée',
  [AccessControlStatus.VALIDATED]: 'Validée',
  [AccessControlStatus.CANCELED]: 'Annulée',
};

export class AccessControlComment {
  @propertyMap()
  public source: 'user' | 'praxedo' | 'system';

  @propertyMap()
  public content: string;

  @propertyMap({type: File})
  public file: File;

  @propertyMap({type: EmbededUser})
  public user: EmbededUser;

  @propertyMap({type: 'Moment'})
  public date: Moment;
}

export class AccessControlAction {
  @propertyMap()
  public status: AccessControlStatus;

  @propertyMap({type: EmbededUser})
  public user: EmbededUser;

  @propertyMap({type: 'Moment'})
  public date: Moment;
}

export class AccessControl {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap()
  public reference: string;

  @propertyMap({type: UnitPriceSchedule})
  public kind: UnitPriceSchedule;

  @propertyMap()
  public status: AccessControlStatus;

  @propertyMap({type: EmbededUser})
  public applicant: EmbededUser;

  @propertyMap({type: EmbededOrganizationalUnit})
  public organizationalUnit: EmbededOrganizationalUnit;

  @propertyMap({type: EmbededEsi})
  public esi: EmbededEsi;

  @propertyMap()
  public purchaseOrderNumber: string;

  @propertyMap()
  public praxedoReferenceUUID: string;

  @propertyMap()
  public purchaseOrderRequestNumber: string;

  @propertyMap()
  public purchaseOrderRequestMessage: string;

  @propertyMap({type: [AccessControlComment]})
  public comments: AccessControlComment[];

  @propertyMap({type: [AccessControlAction]})
  public actions: AccessControlAction[];

  @propertyMap({type: 'Moment'})
  public createdAt: Moment;

  @propertyMap()
  public fields: any;

  @propertyMap({type: 'Moment'})
  public validationDate: Moment;
}
