/** @format */

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Moment} from 'moment';
import {lastValueFrom, Observable} from 'rxjs';
import {LibConfig, LibConfigService} from '../config.service';

@Injectable({
  providedIn: 'root',
})
export class IntentService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public getOperations(reference: string, start: Moment, end?: Moment): Observable<any[]> {
    const params: any = {reference, start: start.toISOString()};
    if (end) params.end = end.toISOString();
    return this.http.get<any[]>(`${this.config.environment.apiUrl}/intent/operations`, {
      params,
    });
  }

  public hasInProgressOperation(reference: string, date: Moment): Observable<boolean> {
    return this.http.get<boolean>(`${this.config.environment.apiUrl}/intent/has-in-progress-operation`, {
      params: {reference, date: date.toISOString()},
    });
  }

  public getMaintenances12Months(id: string, fields?: string): Promise<any[]> {
    const params: any = {equipmentId: id};
    if (fields) params.fields = fields;
    return lastValueFrom(
      this.http.get<any[]>(`${this.config.environment.apiUrl}/intent/maintenances/12-months`, {
        params,
      })
    );
  }
}
