/** @format */

import {Pipe, PipeTransform} from '@angular/core';
import {find, get, isEqual, keys} from 'lodash';

@Pipe({name: 'find'})
export class FindPipe implements PipeTransform {
  transform(data: any[], filterData: any): any {
    if (!data) return data;
    if (typeof filterData === 'string') return find(data, d => d === filterData);
    return find(data, (d: any) => {
      for (let key of keys(filterData)) {
        if (!isEqual(get(d, key), filterData[key])) return false;
      }
      return true;
    });
  }
}
