/** @format */

import {Pipe, PipeTransform} from '@angular/core';
import {each, filter, get, keys, reverse, isEqual} from 'lodash';

@Pipe({name: 'filter'})
export class FilterPipe implements PipeTransform {
  transform(data: any[], filterData: any, options?: {reverse?: boolean}): any[] {
    return this.filter(data, filterData, options?.reverse);
  }

  filter(data: any[], filterData: any, reversed = false) {
    let filtered: any[] = data;
    each(keys(filterData), key => {
      filtered = filter(filtered, d => {
        if (reversed) return !isEqual(get(d, key), filterData[key]);
        return isEqual(get(d, key), filterData[key]);
      });
    });
    return filtered;
  }
}
