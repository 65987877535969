/** @format */

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {isNil} from 'lodash-es';
import moment, {Moment} from 'moment';
import 'moment-timezone';
import {Observable} from 'rxjs';
import {AggregationValue} from '../classes/aggregation';
import {EquipmentKind} from '../classes/equipment-kind';
import {Metric} from '../classes/metrics';
import {LibConfig, LibConfigService} from '../config.service';
import {IChartData} from '../modules/chart/chart.interface';
import {DrilldownService} from './drilldown.service';
import { Granularity } from '../classes/granularity';

export interface ISensorDataFilter {
  organizationalUnit?: {
    id: string;
    name: string;
    pathNames: string[];
  };
  perimeter?: boolean;

  organizationalUnitId?: string;
  realEstateStructureId?: string;
  equipmentId?: string;
  families?: string[];
  kinds?: string[];
  contractorIds?: string[];
  start?: Moment;
  end?: Moment;
  equipmentKind?: EquipmentKind;
  signatureSettingName?: string;
  isTertiary?: boolean;
  unitGrouping?: 'day' | 'hour' | 'minute';
  groupingMethod?: 'sum' | 'avg' | 'count';
  groupingKey?: 'kind' | 'sensorId';
  supervised?: boolean;
  sensorIds?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class SensorDataService {
  constructor(
    @Inject(LibConfigService) protected config: LibConfig,
    protected http: HttpClient,
    private drilldownService: DrilldownService
  ) {}

  public count(
    op: 'sum' | 'avg',
    granularity: Granularity,
    match: {kind?: string; eqId?: string; ouId?: string},
    start?: moment.Moment,
    end?: moment.Moment
  ): Observable<AggregationValue[]> {
    const params: any = {op};
    if (match) {
      if (match.kind) {
        params.kind = match.kind;
      }
      if (match.eqId) {
        params.eqId = match.eqId;
      }
      if (match.ouId) {
        params.ouId = match.ouId;
      }
    }
    switch (granularity) {
      case 'year':
        return this.http.get<any[]>(`${this.config.environment.apiUrl}/sensor-data/by-year`, {params});
      case 'month':
        params.year = start.year();
        return this.http.get<any[]>(`${this.config.environment.apiUrl}/sensor-data/by-month`, {params});
      case 'day':
        params.year = start.year();
        params.month = start.month() + 1;
        return this.http.get<any[]>(`${this.config.environment.apiUrl}/sensor-data/by-day`, {params});
      case 'hour':
        params.year = start.year();
        params.month = start.month() + 1;
        params.day = start.date();
        return this.http.get<any[]>(`${this.config.environment.apiUrl}/sensor-data/by-hour`, {params});
      case 'minute':
        params.year = start.year();
        params.month = start.month() + 1;
        params.day = start.date();
        params.hour = start.hour();
        return this.http.get<any[]>(`${this.config.environment.apiUrl}/sensor-data/by-minute`, {params});
    }
  }

  public getMotionDrilldownData(
    op: 'sum' | 'avg',
    match: {kind: Metric; eqId?: string; ouId?: string},
    granularity?: Granularity,
    date?: Moment
  ): Promise<IChartData[]> {
    return this.drilldownService.getDrilldown(this.count.bind(this), op, match, granularity, date || moment());
  }

  public data(kinds: Metric[], ouId?: string): Observable<{kind: string; values: [number, number][]}[]> {
    const params: any = {kinds};
    return this.http.get<any>(`${this.config.environment.apiUrl}/sensor-data/data`, {params});
  }

  public getChartRangeData(filter: ISensorDataFilter): Observable<{[kind: string]: AggregationValue[]}> {
    return this.http.post<any>(`${this.config.environment.apiUrl}/sensor-data/get-chart-range-data`, filter);
  }

  public getKindDataMetrics(
    kind: Metric,
    equipmentId: string,
    unitGrouping: 'year' | 'month' | 'day',
    start?: Date,
    end?: Date
  ): Observable<{count: number; sum: number; avg: number; min: number; max: number}> {
    return this.http.post<any>(`${this.config.environment.apiUrl}/sensor-data/get-kind-data-metrics`, {
      kind,
      equipmentId,
      unitGrouping,
      start,
      end,
    });
  }

  protected buildSearchFilter(search: ISensorDataFilter): any {
    const filter: any = {};
    if (search?.perimeter) {
      filter.perimeter = true;
    } else if (search?.organizationalUnit) {
      filter.organizationalUnitId = search.organizationalUnit.id;
    }
    if (search?.organizationalUnitId) filter.organizationalUnitId = search.organizationalUnitId;
    if (search?.realEstateStructureId) filter.realEstateStructureId = search.realEstateStructureId;
    if (search?.equipmentId) filter.equipmentId = search.equipmentId;
    if (search?.families) filter.families = search.families;
    if (search?.kinds) filter.kinds = search.kinds;
    if (search?.contractorIds) filter.contractorIds = search.contractorIds;
    if (search.start) filter.start = search.start.toISOString();
    if (search.end) filter.end = search.end.toISOString();
    if (search.equipmentKind) filter.equipmentKindId = search.equipmentKind.id;
    if (search?.signatureSettingName) filter.signatureSettingName = search.signatureSettingName;
    if (search?.isTertiary === true) filter.isTertiary = search.isTertiary;
    if (search?.unitGrouping) filter.unitGrouping = search.unitGrouping;
    if (search?.groupingMethod) filter.groupingMethod = search.groupingMethod;
    if (search?.groupingKey) filter.groupingKey = search.groupingKey;
    if (!isNil(search?.supervised)) filter.supervised = search.supervised;
    if (search?.sensorIds) filter.sensorIds = search.sensorIds;
    return filter;
  }
}
