<mat-form-field *ngIf="multiple && control" [appearance]="appearance" [class.has-prefix]="!!selectControl.value?._id">
  <mat-label translate>{{label}}</mat-label>
  <mat-chip-list #chipList [attr.aria-label]="label">
    <mat-chip *ngFor="let value of $any(control.value)" (removed)="removeAutocompleteValue(value)">
      <!-- <span>{{value.name}}</span> -->
      <div fxLayout="column" fxLayoutAlign="center start">
        <div class="option-prefix-top">
          <span [attr.color]="value.family" translate>
            {{value.family}}
          </span>
        </div>
        <span><span light color="primary">{{value.name}}</span>&nbsp;{{value.name}}</span>
      </div>
      <button matChipRemove [disabled]="isDisabled">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input #autocompleteInput [formControl]="selectControl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
      (focus)="onfocus()">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedAutocompleteValue($event)">
    <mat-option *ngIf="searching" class="full-width" [disabled]="true">
      <mat-progress-bar @fade mode="buffer"></mat-progress-bar>
    </mat-option>
    <ng-container *ngIf="options$ | async as options">
      <mat-option *ngFor="let option of options" [value]="option">
        <div fxLayout="column" fxLayoutAlign="center start">
          <div class="option-prefix-top">
            <span [attr.color]="option.family" translate>
              {{option.family}}
            </span>
          </div>
          <span><span light color="primary">{{option.name}}</span>&nbsp;{{option.name}}</span>
        </div>
      </mat-option>
      <mat-option *ngIf="!searching && searchMatched > options.length" class="full-width">
        <mat-progress-bar @fade mode="buffer" intersectionObserver (intersection)="loadMore($event)"></mat-progress-bar>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <mat-error *ngIf="control.errors && selectControl.touched" [translateParams]="control.errors | values | head"
    translate="form-field-error-{{control.errors | keys | head}}"></mat-error>
</mat-form-field>

<mat-form-field *ngIf="!multiple && control" [appearance]="appearance" [class.has-prefix]="!!selectControl.value?._id">

  <mat-label translate>{{label}}</mat-label>

  <input matInput type="text" [formControl]="selectControl" [matAutocomplete]="auto" (focus)="onfocus()"
    autocomplete="off">

  <div *ngIf="selectControl.value?._id" matTextPrefix>
    <div class="prefix-top">
      <span [attr.color]="selectControl.value.family" translate>
        {{selectControl.value.family}}
      </span>
    </div>
  </div>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayOption">
    <mat-option *ngIf="searching" class="full-width" [disabled]="true">
      <mat-progress-bar @fade mode="buffer"></mat-progress-bar>
    </mat-option>
    <ng-container *ngIf="options$ | async as options">
      <mat-option *ngFor="let option of options" [value]="option">
        <div fxLayout="column" fxLayoutAlign="center start">
          <div class="option-prefix-top">
            <span [attr.color]="option.family" translate>
              {{option.family}}
            </span>
          </div>
          <span><span light color="primary">{{option.name}}</span>&nbsp;{{option.name}}</span>
        </div>
      </mat-option>
      <mat-option *ngIf="!searching && searchMatched > options.length" class="full-width">
        <mat-progress-bar @fade mode="buffer" intersectionObserver (intersection)="loadMore($event)"></mat-progress-bar>
      </mat-option>
    </ng-container>
  </mat-autocomplete>

  <button *ngIf="selectControl.value" mat-icon-button size="small" matIconSuffix class="form-field-clear-btn"
    tabindex="-1" (click)="selectControl.setValue(null)">
    <mat-icon>close</mat-icon>
  </button>

  <mat-error *ngIf="control.errors && selectControl.touched" [translateParams]="control.errors | values | head"
    translate="form-field-error-{{control.errors | keys | head}}"></mat-error>

</mat-form-field>